import React, { Component } from 'react';
import {Trans} from "react-i18next";

export default class Settings extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() { 
        return ( <h1><Trans>Settings</Trans></h1> );
    }
}
 
