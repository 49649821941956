import React, { useEffect, useRef} from 'react'
import './KnowledgeGraph.css'
import ForceGraph from 'react-force-graph-2d'
import graphData from '../../assets/data/graph_data.json'


const KnowledgeGraph = () => {
    const [value, setValue] = React.useState("");

    const forceRef = useRef(null);

    useEffect(() => {
        forceRef.current.d3Force('charge').strength(-20);
    });

    const onChange = (e) => {
        return setValue(e.target.value);    
    } 


    return (
        <div className="knowledge_graph">
            <div className="row">
                <div className="col-2">
                    <div className="filter">
                        <div className="filter-header">
                            Filter
                            <span>  
                                <input
                                    value={value}
                                    onChange={onChange}
                                    placeholder={`Suchen...`}
                                    style={{
                                    fontSize: '1.1rem',
                                    border: '0',
                                    }}
                                />
                            </span>
                        </div>
                        {/* <div className="filter-header">
                            Filter
                        </div> */}
                        <div className="filter-body">
                            <ul>
                                <li><span className="badge badge-pill badge-warning">Schwachstelle</span></li>
                                <li><span className="badge badge-pill badge-primary">Advisory</span></li>
                                <li><span className="badge badge-pill badge-info">CERT</span></li>
                                <li><span className="badge badge-pill badge-secondary">3rd party</span></li>
                                <li><span className="badge badge-pill badge-danger">Exploit</span></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="col-10">
                    <ForceGraph
                        graphData={graphData}
                        nodeAutoColorBy={d => graphData.nodes[d].group}
                        nodeLabel="id"
                        nodeCanvasObject={({id, x, y}, ctx) => {
                            ctx.fillStyle = graphData.nodes.filter(n => n.id === id)[0].color;
                            ctx.beginPath();
                            ctx.arc(x, y, 5, 0, 2 * Math.PI, false);
                            ctx.fill();
                            ctx.font = '10px Sans-Serif';
                            ctx.textAlign = 'center';
                            ctx.textBaseline = 'middle';
                            ctx.fillText(graphData.nodes.filter(n => n.id === id)[0].name, x, y);
                            ;
                        }}
                        enablePointerInteraction={true}

                        ref={forceRef}
                    />,
                </div>
            </div>
        </div>
    );
}
export default KnowledgeGraph;
