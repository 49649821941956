import React from 'react';

const Title = ({title}) => {
  return (
    <p className="dashboard-title">
      {title}
    </p>
  );
}

export default Title;
