import React from 'react';
import {
    COLOR_FILTER_COUNT
} from "../../constants";
const FilterCount = ({count}) => {


    return (<span className="count" style={{
      padding: "4px 6px",
      backgroundColor: COLOR_FILTER_COUNT,
      borderRadius: "4px",
      width: "auto",
      height: "23px",

      fontFamily: "Inter-2",
      fontStyle: "normal",
      fontWeight: "600",
      fontSize: "12px",
      lineHeight: "15px",
      textTransform: "uppercase",
      color: "#808C9C",
      

    }}>{count}</span>)

}
export default FilterCount
