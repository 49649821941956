
import React, {useState} from "react"
import { RiskLabel } from '../Common/RiskLabel';
import { VulnerabilityLabelSVG, LetterIcon } from "../SVG/common"
import './Insights.css'
//import { Trans } from "react-i18next";
import { NavLink, useRouteMatch } from "react-router-dom";
import convertDateToUtc_Short from "../Common/helpers"
import DomainWidget from "../Common/DomainWidget";



const InsightsTable = ({ insights, activeFilter }) => {
  //getting Tenant ID from the URL
  const match = useRouteMatch();
  const tenantId = match.params.id
  // max length a String in Insights/Risks can have
  const maxLength = 40;

  if(insights === null || insights === undefined || insights.length === 0)
    return     <><table className="table-in-card">
    <thead>
      <tr>
        <th className="width-id alignment">ID</th>
        <th className="width-insight alignment">Insight</th>
        <th className="width-update alignment">Last Update</th>
        <th className="width-risk alignment">Risk</th>
      </tr>
    </thead>
  </table>
  <p className="no-devices">No insights found</p>
  </>

  return (insights.length !== 0 ?
    <table className="table-in-card">
      <thead>
        <tr>
          <th className="width-id alignment">ID</th>
          <th className="width-insight alignment">Insight</th>
          <th className="width-update alignment">First Seen At</th>
          <th className="width-risk alignment">Risk</th>
        </tr>
      </thead>
      <tbody>
        {insights.map((ele, i) => {
          return (
            <tr key={i} >
              <td className="alignment"> <NavLink to={`/${tenantId}/insights/${ele.id}`}><VulnerabilityLabelSVG value={23} /><span style={{ marginLeft: "0.5rem" }}>{ele.id}</span></NavLink></td>
              <td className="alignment"><NavLink to={`/${tenantId}/insights/${ele.id}`}>{ele.displayName.length > maxLength ? ele.displayName.slice(0, maxLength) + "..." : ele.displayName}</NavLink></td>
              <td className="alignment"><NavLink to={`/${tenantId}/insights/${ele.id}`}>{convertDateToUtc_Short(ele.firstSeenAt)}</NavLink></td>
              <td className="alignment" ><NavLink to={`/${tenantId}/insights/${ele.id}`}><RiskLabel risk={ele.risk} /></NavLink></td>
            </tr>
          )
        })}
      </tbody>
    </table>
    : <p className="no-devices">No insights found.</p>
  );
}

export default InsightsTable;
