import React from 'react';
import { useRouteMatch, NavLink } from 'react-router-dom'
import "./LandscapeTD.css"

const RiskDot = ({ risk }) => {
  let fill 

  switch(risk){
    case "LOW": 
      fill = "#FCBF24"
      break;
    case "MEDIUM": 
      fill = "#FF841E"
      break;
    case "HIGH":
      fill = "#DA4347"
      break;
    case "CRITICAL":
      fill = "#AD45B9"
      break;
    default :
      fill = "#8c8c8c"
  }

  return (
    <svg style={{margin: "-3 8 0 5"}} width="10" height="24" viewBox="0 0 10 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="5" cy="12" r="5" fill={fill} />
    </svg>

  );
}


const LandscapeTD = ({ value, risk, deviceId }) => {
  const match = useRouteMatch();
  const tenantId = match.params.id
  let background 
  let border
  switch(risk){
    case "LOW": 
    border = "#FCBF24"
    background = "rgba(252, 191, 36, 0.7)"
    break;
  case "MEDIUM": 
    border = "#FF841E"
    background = "rgba(255, 132, 30, 0.7)"
    break;
  case "HIGH":
    border = "#DA4347"
    background = "rgba(218, 67, 71, 0.7)"
    break;
  case "CRITICAL":
    border = "#AD45B9"
    background = "rgba(173, 69, 185, 0.7)"
    break;
  default :
    border = "#8c8c8c"
    background = "#adadad"
    break;
  }

  if(!value) {
    return <td className="empty-cell"></td>;
  }
  return (
    <td className="landscape-item" 
    style={{
      background: background,
      border: `2px solid ${border}`
    }}><NavLink to={`/${tenantId}/devices/${deviceId}`}><RiskDot risk={risk}/> {value}</NavLink></td>
  );
}

export default LandscapeTD;
