import React from 'react'
import Helmet from "react-helmet"
// import SidebarWithoutNavItems from "../components/Sidebar/SidebarWithoutNavItems"
import TopbarWithoutNavitems from "../components/Topbar/TopbarWithoutNavitems"

const LayoutWithoutSidebar = (props) => {

  return (<>
    <Helmet>
      <title>CISOSCOPE</title>
      <link rel="canonical" href="https://app.cisoscope.com" />
    </Helmet>
    <TopbarWithoutNavitems />
    <div id="content-wrapper" className="d-flex flex-column">
      {React.createElement(props.children, props)}
    </div>
  </>
  )

}

export default LayoutWithoutSidebar


