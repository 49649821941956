import React from 'react';
import {
    COLOR_RISK_CRITICAL,
    COLOR_RISK_HIGH,
    COLOR_RISK_LOW,
    COLOR_RISK_MEDIUM,
    COLOR_RISK_UNKOWN
} from "../../constants";


/**
* Convert a risk label to an orderable integer
*/
const riskToInt = (r) => {
  switch (r.toLowerCase()) {
    case "info":      return 0;
    case "low":       return 1;
    case "medium":    return 2;
    case "high":      return 3;
    case "critical":  return 4;
    default:          return 0;
  }
}

/**
* Convert an integer back into a risk label
*/
const intToRisk = (r) => {
  switch(r) {
    case 0: return  "info";
    case 1: return  "low";
    case 2: return  "medium";
    case 3: return  "high";
    case 4: return  "critical";
    default: return "info";
  }
}

/**
* get the maximum risk from a collection of risk labels (array of strings)
*/
let getMaxRisk = (risks) => {
  return intToRisk(Math.max(...risks.map(riskToInt)));
}




const RiskLabel = (props) => {
    let {risk} = props
    let color = COLOR_RISK_UNKOWN
    switch (risk.toUpperCase()) {
        case "CRITICAL":
            color = COLOR_RISK_CRITICAL
            break;
        case "HIGH":
            color = COLOR_RISK_HIGH
            break;
        case "MEDIUM":
            color = COLOR_RISK_MEDIUM
            break;
        case "LOW":
            color = COLOR_RISK_LOW
            break;
        default:
            color = COLOR_RISK_UNKOWN
            break;
    }

    return (<span style={{
      padding: "4px 6px",
      backgroundColor: color,
      borderRadius: "4px",

      fontFamily: "Inter-2",
      fontStyle: "normal",
      fontWeight: "600",
      fontSize: "12px",
      lineHeight: "15px",
      textTransform: "uppercase",
      color: "#FFFFFF",

    }}>{risk}</span>)

}
export { getMaxRisk, riskToInt, RiskLabel }
