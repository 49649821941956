/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import "./Pagination.css"
import {PrevPageSVG, NextPageSVG } from "../SVG/common";

export default function Pagination({
  amountPages,
  setCurrentPage,
  skipItems,
  setSkipItems,
  findingCount,
  get_Filtered_Data,
  filters})
  {

  // const match = useRouteMatch();
  // const tenantId = match.params.id
  // const [currentButton, setCurrentButton] = useState(1)
  let pages = [];

  const setPages = () => {
    // calculating pages based on amount of items
    // e.g 61 items = 7 pages, 39 items = 4 pages (10 items per page)
    for(let i = 0; i < amountPages; i++){

        pages.push(
            <li
                key={i+1}
                className={"page-item " + (skipItems === i * 10 ? "active" : "")}
                onClick={() => {

                    setSkipItems(i * 10);
                }}
            ><a className="page-link" href="#">{i+1}</a></li>
        )
      }

    if(amountPages >= 10){
      pages = [...pages];
        function DotsInitial() {
          return (
            <li
              key={Math.random() * 1000}
              className={"page-item disabled"}
            ><a className="page-link" href="#">...</a></li>
        )
            }
      // let dotsLeft = "... ";
      // let dotsRight = " ...";

      if(skipItems >= 0 && skipItems <= 20){
        pages = [pages.slice(0,4), <DotsInitial key={"dots"}/>, pages.slice(-1)]
      }
      else if(skipItems > 20 && skipItems < (pages.length-2)*10){
        const sliced1 = pages.slice(skipItems/10 - 1, skipItems/10)
        const sliced2 = pages.slice(skipItems/10, skipItems/10 + 2)
        pages = ([pages[0], <DotsInitial key={"dotsLeft"}/>, sliced1, sliced2, <DotsInitial key={"dotsRight"}/>, pages.slice(-1)])
      }
      else if(skipItems > (pages.length - 3) * 10){
        const sliced = pages.slice(pages.length - 4)
        pages = ([pages[0], <DotsInitial key={"dots"}/>, ...sliced]);
      }
    }
  }
  setPages()

  return(
    <>
      <nav>
        <ul className="pagination">
        {pages}
        <div className="arrows">
            <li className={"page-item " + (skipItems === 0 ? "disabled" : "")}
            onClick={() => {
              setSkipItems((prev) => {
                return prev === 0 ? prev : prev - 10
              })}}>
              <a className="page-link" href="#"><PrevPageSVG /></a>
            </li>



            <li className={"page-item " + (skipItems === ((amountPages-1)*10 || 0) ? "disabled" : "")}
              onClick={() => {
                setSkipItems((next) => {
                return (next + 10) / 10 < amountPages ? next + 10 : next
                })}}>
              <a className="page-link" href="#"><NextPageSVG /></a>
            </li>
            </div>
        </ul>
      </nav>
    </>

  )
}
