/* eslint-disable no-unused-vars */
import React from 'react';

const responseOptions = ["NEW", "ELIMINATE", "MITIGATE", "TOLERATE", "FALSE_POSITIVE"]

const Label = ({label}) => {
  return (<span style={{
    padding: "4px 6px",
    backgroundColor: "#0F57A6",
    borderRadius: "4px",

    fontFamily: "Inter-2",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "12px",
    lineHeight: "15px",
    textTransform: "uppercase",
    color: "#FFFFFF",
    height: "23px",
    width: "fit-content"

  }}>{label}</span>)
}

const enumToString = (response) => {
  switch(response) {
    case "DONE": return "done";
    case "NEW": return 'new';
    case "ELIMINATE": return 'eliminate';
    case "MITIGATE": return 'mitigate';
    case "TOLERATE": return 'tolerate';
    case "FALSE_POSITIVE": return 'false positive';
    default: return 'ERROR';
  }
}

const stringToEnum = (response) => {
  switch(response) {
    case "done": return "DONE";
    case "new": return 'NEW';
    case "eliminate": return 'ELIMINATE';
    case "mitigate": return 'MITIGATE';
    case "tolerate": return 'TOLERATE';
    case "false positive": return 'FALSE_POSITIVE';
    default: return 'ERROR';
  }
}

const labelAggregate = (labels) => {
  if(labels.every((label) => (label.toLowerCase() === "new"))) {
    return labels[0]
  }
}

const countUnique = (labels) => {
  return new Set(labels).size;
}

const deriveGroupLabel = (labels) => {
  // all or at least one label is "NEW" => show New label
  if(labels.every((label) => (enumToString(label).toLowerCase() === "new")) ||
     labels.some((label) => (enumToString(label).toLowerCase() === "new"))) {
    return (
      <div style={{display: "flex",
                   flexDirection: "row"}}>
        <Label label={enumToString("NEW")} />
      </div>
    )
  } else {
    return (
      <div style={{display: "flex",
                   flexDirection: "row"}}>
        <Label label={enumToString(labels[0])} />
        <span style={{marginLeft: "4px",
                      fontStyle: "normal",
                      fontWeight: "500",
                      fontSize: "16px",
                      color: "#B5C1CE",
                      cursor: "pointer"}}>
          +{(countUnique(labels) - 1)}
        </span>
      </div>
    )
  }
}

const ResponseLabel = ({labels}) => {
  if(!Array.isArray(labels)) {
    return (<Label label={enumToString(labels)} />)
  } else {
    return deriveGroupLabel(labels);
  }

}

export { ResponseLabel, enumToString, stringToEnum, responseOptions }
