import React, { useState } from 'react';
import { GreyShowLess } from '../SVG/common';


const DomainWidget = ({ businessDomainNames, activeFilter }) => {
  const [showAllDomains, setShowAllDomains] = useState(false)

  // format the domain to filter readable String
  // e.g Enterprise Support => enterprise_support
  function formatToFilter(e) {
    return e.split(" ").join("_").toLowerCase();
  }
  // format the domain name to human readable String
  // e.g enterprise_support => Enterprise Support
  function formatToHuman(e) {
    let newString = e.split("_").join(" ").split(" ")
    for (let i = 0; i < newString.length; i++) {
      newString[i] = newString[i].charAt(0).toUpperCase() + newString[i].slice(1)
    }
    return newString.join(" ")
  }
  businessDomainNames.map((e, i) => {
    let activeDomain = formatToFilter(e);
    if (activeFilter.has(`business_domain_${activeDomain}`) || businessDomainNames > 1) {
      businessDomainNames.splice(businessDomainNames.indexOf(e), 1)
      businessDomainNames.unshift(formatToHuman(activeDomain))
      // console.log(businessDomainNames)
    }
  })

  /**TO DO */
  // small bug: the array is being manipulated in a weird way
  // e.g : [Domain1] (collapsed) -> [Domain3, Domain2, Domain1] (open)
  // possible fix: alternate the businessDomainNames Array before
  // rendering and put the active Domain Name as first Domain
  // console.log(businessDomainNames)
  return (
    <div onClick={() => { setShowAllDomains(!showAllDomains) }}>{businessDomainNames.map((e, i) => {
      //console.log(businessDomainNames)
      let activeDomain = formatToFilter(e);
      if (activeFilter.has(`business_domain_${activeDomain}`) || businessDomainNames.includes(e)) {
        return <div key={activeDomain + i} style={{ display: i < 1 || showAllDomains ? "block" : "none" }}>{e}
          <span className="show-more" style={{ cursor: "pointer" }}>{businessDomainNames.length - 1 >= 1 && !showAllDomains ? ` + ${businessDomainNames.length - 1} more` : ""}</span>
        </div>
      }
    })}{showAllDomains && businessDomainNames.length > 1 ? <span className="show-more" style={{ cursor: "pointer" }}>Show Less  <GreyShowLess /></span> : ""}</div>
  );
}

export default DomainWidget;
