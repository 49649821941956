export default function convertDateToUtc_Short(date) {
  // split time/date and save for further modification
  const dateAndTime = date.split("T");
  const d = dateAndTime[0]
  const t = dateAndTime[1]
  // reverse the date from YYYY-MM-DD to DD-MM-YYYY and get the month 
  let date_reversed = d.split("-").reverse().join(" ")
  let month = date_reversed.split(" ")[1] 

  switch(month){
    case "01":
      month = "Jan"
      break;
    case "02":
      month = "Feb"
      break;
    case "03":
      month = "Mar"
      break;
    case "04":
      month = "Apr"
      break;
    case "05":
      month = "May"
      break;
    case "06":
      month = "Jun"
      break;
    case "07":
      month = "Jul"
      break;
    case "08":
      month = "Aug"
      break;
    case "09":
      month = "Sep"
      break;
    case "10":
      month = "Okt"
      break;
    case "11":
      month = "Nov"
      break;
    case "12":
      month = "Dez"
      break;
    default:
      break;
  }
  // replace the month with String instead of Int and merge the Strings 
  let newDate = date_reversed.split(" ");
  newDate[1] = month
  let finalDate = newDate.join(" ")
  let newTime = t.slice(0, 5)
  finalDate = finalDate.concat(" ",newTime)


  return finalDate
 
  
}