import {Redirect, Route} from "react-router-dom";
import React from "react";
import {AUTH_TOKEN} from "../constants";
import LayoutWithSidebar from "../layouts/LayoutWithSidebar";
import LayoutWithoutSidebar from "../layouts/LayoutWithoutSidebar";


const PrivateRoute = ({layout, component, ...rest}) => {
    const token = localStorage.getItem(AUTH_TOKEN)
    return (
        <Route {...rest} exact
               render={(props) => (
                   token ? (
                            layout==="LayoutWithSidebar" ? (
                                <LayoutWithSidebar>
                                {component}
                                </LayoutWithSidebar>
                            ) :
                            (<LayoutWithoutSidebar>
                                {component}
                                </LayoutWithoutSidebar>)
                       ) :
                       (
                           <Redirect
                               to={{
                                   pathname: '/login',
                                   from: props.location,
                               }}
                           />
                       )
               )}
        />
    )
}


export default PrivateRoute;
