/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useCallback } from 'react'
import gql from 'graphql-tag'
import { useQuery, useMutation } from 'react-apollo'
import { NavLink, useRouteMatch } from 'react-router-dom'
import {useHistory} from "react-router";
import { Trans } from 'react-i18next'
import styled from "styled-components";
import Loader from '../../Common/Loader'
import { FilterWidget, deriveFilterQuery } from '../../Filter/Filter';
import InsightsTable from "../../Insights/InsightsTable"
import "../../Insights/Insights.css"
// import VulnerabilitySVG from '../../Common/VulnerabilitySVG'
import { VulnerabilityLabelSVG, DropdownArrowSVG, BackArrowSVG } from "../../SVG/common"
import { TableTextField, TableLimitedTextField, TableTextFieldWithExpand, TableTextFieldWithLink,
         InsightTypeField } from "../../DynamicTable/Fields";
import { TableGroupRow, PaginationWidget } from "../../DynamicTable/DynamicTable";
import { getMaxRisk, RiskLabel } from '../../Common/RiskLabel'
import DomainWidget from "../../Common/DomainWidget";
import convertDateToUtc_Short from "../../Common/helpers"
import { Dropdown } from "react-bootstrap"
import { groupByFn, select } from '../../../utils/Functionals';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faCaretDown, faSignIn } from '@fortawesome/pro-solid-svg-icons'


const Styles = styled.div`


overflow-x: hidden;

.backlink {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 32px;
}

.back-btn {
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #808C9C;
  cursor: pointer;
}

}
.device {
  margin-top: 16px;
}
.device-heading {
  margin-left: 52px;
  margin-top: 1.5rem;
  color: #0D276B;

  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 27px;
}

.table-in-card.details tbody tr:hover {
  background-color: #FFFFFF;
  cursor: initial;
}

.table-in-card.details tbody td {
  border-bottom: none;
}


.small-heading{
  color: #0D276B;
  margin-left: 52px;
  margin-bottom: -8px;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 28px;
}

.margin-left{
  margin-left: 36px;
}

.action-dropdown{
  width: 51px;
  height: 28px;
  background: #0F57A6;
  border-radius: 34px;
  color: #FFFFFF;
  padding: 12px 16px 12px 16px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  text-decoration: none;
}
.arrow-margin{
  margin-left: 9px;
}

.details-table-card {
  /* padding: 32px; */
  height: auto;
  color: #0D276B;
}

th.software {
  width: 24rem;
}

th.update {
  width: 12rem;
}

th.risk {
  width: 6rem;
}

.device-details-title-bar {
  display: flex;
  flex-direction: row;
}

.device-state-label {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-top: 26px;
  margin-left: 8px;
  padding: 4px 6px;
  color: #FFFFFF;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  background: #808C9C;
  border-radius: 4px;
  height: fit-content;
}
`


const GET_DEVICE_DETAILS = gql`
query DeviceDetailsQuery($tenantId: String!, $device_id: String!){
  device(tenantId: $tenantId, id: $device_id) {
    id
    displayName
    state
    operatingSystemName
    businessDomainNames
    hostname
    publicIp
    lastScannedAt
    risk

    insights {
      insightType
      id
      displayName
      updatedAt
      risk
      platforms {
        name
        version
      }
    }
  }
}
`


const ACTIVATE_DEVICE_MUTATION = gql`
mutation activateDeviceMutation($tenantId: String!, $deviceId: String!) {
  activateDevice(tenantId: $tenantId, deviceId: $deviceId) {
    __typename
    device {
      id
      displayName
      active
      operatingSystemName
      businessDomainNames
      hostname
      publicIp
      lastScannedAt
      risk

      insights {
        insightType
        id
        displayName
        updatedAt
        risk
        platforms {
          name
          version
        }
        risk
      }
    }
  }
}
`

const DEACTIVATE_DEVICE_MUTATION = gql`
mutation deactivateDeviceMutation($tenantId: String!, $deviceId: String!) {
  deactivateDevice(tenantId: $tenantId, deviceId: $deviceId) {
    __typename
    device {
      id
      displayName
      active
      operatingSystemName
      businessDomainNames
      hostname
      publicIp
      lastScannedAt
      risk

      insights {
        insightType
        id
        displayName
        updatedAt
        risk
        platforms {
          name
          version
        }
        risk
      }
    }
  }
}
`


/**
Dynamically build an array of available filters from the data fetched from the backend.
*/
// const filterFromData = (data) => {
//   //console.log("Available Filter: ", data)
//   let filter =
//     data.map((category) => {
//       return category.values.map((option) => {
//         return {
//           category: category.field,
//           field: category.field,
//           value: option.name,
//           count: option.count,
//           ident: category.field + "_" + option.name.toLowerCase().replaceAll(' ', '_')
//         }
//       })
//     })
//   return [].concat.apply([], filter)
// }

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <a
    href="!#"
    className="action-dropdown"
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
    <DropdownArrowSVG />
  </a>
));

const aggregateInsightTypes = (insights) => {
  //return insights[0];
  return {type: "vulnerability", count: insights.length};
}

const flattenInsights = (col) => {
  return col.reduce((acc, e) => {
            e.platforms.forEach( (p) => {
              acc.push({platform: p.name + " " + p.version,
                        insightType: e.insightType,
                        updated: e.updatedAt,
                        risk: e.risk,
                        id: e.id,
                        name: e.displayName})
            })
            return acc;
          }, [])
}

const insightsToTableData = (insights) => {
  //console.log("Expanded Insights: ", flattenInsights(insights))

  let groupedInsights = groupByFn(flattenInsights(insights), (e) => {
    return e.platform});

  //console.log("Grouped Insights: ", groupedInsights)

  return Object.entries(groupedInsights).map( ([key, items]) => {
    return {platform: items[0].platform,
            insight: aggregateInsightTypes(select(items, (item) => item.insightType)),
            updated: items[0].updated,
            risk: getMaxRisk(select(items, (item) => item.risk)),
            children: items.map((child) => {
              return {id: child.id,
                      name: child.name,
                      updated: child.updated,
                      risk: child.risk}
            })}
  })
}

/**
* Select and render a table field widget. Returns the correct component for the field depending
* on the table field declaration.
*/
const renderWidget = (widget, value, props = {}) => {
  switch(widget) {
    case "TableTextField":
      return (<TableTextField value={value} props={props}/>);
    case "TableLimitedTextField":
      return (<TableLimitedTextField value={value} props={props}/>);
    case "TableLinkField":
      return (<TableTextFieldWithLink value={value} location={`/${props._tennantId}/insights/${props._insightId}`}/>);
    case "TextFieldWithExpand":
      return (<TableTextFieldWithExpand
                value={value}
                expanded={props.expanded}
                toggleExpanded={props.toggleExpanded}
              />);
    case "InsightTypeField":
      return (<InsightTypeField type={value.type} count={value.count}/>)
    case "RiskLabel":
      return (<RiskLabel risk={value} />);
    case "Empty":
      return <br/>
    default:
      return (<TableTextField value={value} props={props}/>);
  }
}

const getWidgetFromName = (fieldName, fields) => {
  return fields.find((fd) => { if(fd.name === fieldName) { return fd.component }});
}


/**
* Widget to show that no entries for the details view are available
*/
const NoEntries = () => {
  return (
    <div className="no-entries">
      <Trans>There are no insights available about this Device.</Trans>
    </div>
  )
}

const DeviceStateLabel = ({state}) => {
  return (
    <span className="device-state-label">
      <Trans>{state}</Trans>
    </span>
  )
}

const DeviceDetails = (props) => {
  let match = useRouteMatch();
  const history = useHistory();
  const device_id = match.params.device_id;
  const tenantId = match.params.id;
  // let { url } = useRouteMatch();

  // an ordered list (array) of all filters a user can select
  const [availableFilter, setAvailableFilter] = useState([]);

  // The filters that are currently active. The value in the set is the ident
  // of the filter in the availableFilter list
  const [activeFilter, setActiveFilter] = useState(new Set());

  // Compose an update function for the filters
  const updateActiveFilter = useCallback((filters) => setActiveFilter(filters),
    [availableFilter]);

  // paginagtion: default entries per page and state
  const entriesPerPage = 10;
  let [currentPage, setCurrentPage] = useState(1);

  const { loading, error, data } = useQuery(GET_DEVICE_DETAILS, {
    variables:
    {
      tenantId: tenantId,
      device_id: device_id,
      filters: deriveFilterQuery(availableFilter, activeFilter),
    },
  });


  // activate device with a gql mutation
  const [activateDeviceMutation] = useMutation(ACTIVATE_DEVICE_MUTATION, {
          onError: ({graphQLErrors, networkError}) => {
              console.error("Mutation activateDeviceMutation Failed!")
          },
          refetchQueries: ["DeviceDetailsQuery"],
          onCompleted: (data) => {
            console.log("Mutation activateDeviceMutation Successful!")
          }
      }
  );

  // deactivate device with a gql mutation
  const [deactivateDeviceMutation] = useMutation(DEACTIVATE_DEVICE_MUTATION, {
          onError: ({graphQLErrors, networkError}) => {
              console.error("Mutation activateDeviceMutation Failed!")
          },
          refetchQueries: ["DeviceDetailsQuery"],
          onCompleted: (data) => {
            console.log("Mutation activateDeviceMutation Successful!")
          }
      }
  );

  const activateDevice = (deviceId) => {
    activateDeviceMutation({variables: {tenantId, deviceId}});
  }

  const deactivateDevice = (deviceId) => {
    deactivateDeviceMutation({variables: {tenantId, deviceId}});
  }



  // Uncomment and add filterOptions when available in Backend!!!
  // useEffect(() => {
  //   if ((data !== undefined) && (availableFilter.length === 0)) {
  //     setAvailableFilter(filterFromData(data.devices.filterOptions))
  //   }
  // },
  //   [data]);
  if (loading) return <Loader />;
  if (error) return <p>Error!!</p>;

  const {
     businessDomainNames, displayName, hostname, operatingSystemName, lastScannedAt, publicIp, insights, risk,
  } = data.device;

  let insightsData = insightsToTableData(data.device.insights)

  // Declare how the table groups are rendered using a dynamic table
  const tableGroupFields = [{name:      "platform",
                             component: "TextFieldWithExpand"},
                            {name:      "insight",
                             component: "InsightTypeField"},
                            {name:      "updated",
                             component: "TextField"},
                            {name:      "risk",
                             component: "RiskLabel"}];
  // Declare how the table data rows are rendered using a dynamic table
  const tableChildrenFields = [{name:      "id",
                                component: "TableLinkField"},
                               {name:      "name",
                                component: "TableTextField"},
                               {name:      "updated",
                                component: "TextField"},
                               {name:      "risk",
                                component: "RiskLabel"}];

    const selectWidget = (name, value, context, props = {}) => {
      if(name === "updated") {
        value = convertDateToUtc_Short(value)
      }
      return renderWidget(getWidgetFromName(name, tableGroupFields).component, value, props)
    };

    const selectChildrenWidget = (name, value, context, props = {}) => {
      if(name === "id") {
        //console.log("Context: ", context)
        props._insightId = context.id;
        props._tennantId = tenantId;
      }
      if(name === "updated") {
        value = convertDateToUtc_Short(value)
      }
      return renderWidget(getWidgetFromName(name, tableChildrenFields).component, value, props)
    };


  let softwareCount = insightsData.length
  let insightsCount = new Set(select(Object.values(select(insightsData, (o) => o.children)).flat(), (o) => o.id)).size;

  return (

    <Styles>
      <div className="row">
        {data.length === 0 ? null : <div className="col-2">
          <FilterWidget
            availableFilter={availableFilter}
            activeFilter={activeFilter}
            updateActiveFilter={updateActiveFilter}
          />
        </div>}
        <div className="col-sm-9">
          <div className="margin-left flex justify-content-between">
            <div className="backlink"
                onClick={() => history.goBack()}>
              <div className="back-btn">
                <BackArrowSVG />
                <span style={{marginLeft: "4px"}}>Back</span>
              </div>
            </div>
            <Dropdown id="dropdown-menu-align-right" style={{ marginTop: "30px", marginRight: "36px" }}>
              <Dropdown.Toggle as={CustomToggle} >
                Action
             </Dropdown.Toggle>
              <Dropdown.Menu >
                {data.device.state.toLowerCase() === "active" ?
                  <Dropdown.Item
                    onClick={() => deactivateDevice(data.device.id)}>
                    <Trans>Set Device to Inactive</Trans>
                  </Dropdown.Item>
                  :
                  <Dropdown.Item
                    onClick={() => activateDevice(data.device.id)}>
                    <Trans>Set Device to Active</Trans>
                  </Dropdown.Item>
                }
                {/* <Dropdown.Divider /> */}
                {/* <Dropdown.Item disabled>Show in Knowledge Graph</Dropdown.Item>*/}
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className="device-details-title-bar">
            <h4 className="device-heading">{displayName}</h4>
            <DeviceStateLabel state={data.device.state}/>
          </div>
          <div className="background-table insights device">
            <table className="table-in-card details" key="device-details">
              <thead>
                <tr>
                  <th className="alignment">Operating System</th>
                  <th className="alignment">Business Domain</th>
                  <th className="alignment">Host Name</th>
                  <th className="alignment">Public IP</th>
                  <th className="alignment">Last Scan</th>
                  <th className="alignment">Risk</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="alignment" >{operatingSystemName}</td>
                  <td className="alignment" style={{width: "18rem"}}><DomainWidget businessDomainNames={businessDomainNames.map(domain => domain)} activeFilter={activeFilter}/></td>
                  <td className="alignment" style={{width: "14rem"}}>{hostname}</td>
                  <td className="alignment" style={{width: "10rem"}}>{publicIp}</td>
                  <td className="alignment" style={{width: "12rem"}}>{convertDateToUtc_Short(lastScannedAt)}</td>
                  <td className="alignment" style={{width: "6rem"}}><RiskLabel risk={risk} /></td>
                </tr>
              </tbody>
            </table>
          </div>

          <p className="small-heading">Last Insights</p>
          <div className="background-table insights device">
            <div className="details-table-card">
              {(insightsData === null || insightsData === undefined || insightsData.length === 0)
               ?
                <NoEntries />
               :
                <table className="table-in-card ">
                  <thead>
                    <tr>
                      <th className="alignment software">
                        <Trans>Software</Trans>
                        {" ("+softwareCount+")"}
                        {" / "}
                        <Trans>Insights</Trans>
                        {" ("+insightsCount+")"}
                      </th>
                      <th className="alignment insight"><Trans>Insights</Trans></th>
                      <th className="alignment update"><Trans>First Seen</Trans></th>
                      <th className="alignment risk"><Trans>Risk</Trans></th>
                    </tr>
                  </thead>
                    {insightsData.slice((currentPage-1)*entriesPerPage, currentPage*entriesPerPage).map((d) =>
                      <TableGroupRow
                        key={d.platform}
                        row={d}
                        fields={tableGroupFields}
                        childrenFields={tableChildrenFields}
                        widgetFn={selectWidget}
                        childrenWidgetFn={selectChildrenWidget}
                      />
                    )}
                </table>}
              </div>
            </div>
            <div style={{padding: "0 32px 0 16px"}}>
              {(insightsData === null || insightsData === undefined || insightsData.length <= 1)
                ? null
                : <PaginationWidget
                    current={currentPage}
                    setCurrent={setCurrentPage}
                    totalPages={Math.ceil(insightsData.length / entriesPerPage)}
                  />}
            </div>
        </div>
      </div>
    </Styles>
  )
}


export default DeviceDetails
