import InsightsTable from "../Insights/InsightsTable";
import React from "react";


const DeactivatedFeature = () => {
    return (
        <div className="background-table insights">
                <p className="no-devices">This feature is not part of your purchased license.
                    Please contact sales@cisoscope.com for further information.</p>
        </div>
    );
}
export default DeactivatedFeature;
