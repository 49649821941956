import React from "react"

import './Tasks.css'
// import Filter from "../Filter/Filter";

const Tasks = () => {
    return (
        <div className="tasks">
            <div className="row">
                <div className="col-2">
                {//<Filter />
                }
                </div>


                <div className="col-9">
                    <div className="row">
                        <div className="col-12 text-right">
                            <div className="btn-group">
                                <button type="button" className="btn btn-default dropdown-toggle"
                                        data-toggle="dropdown">
                                    Aktion <span className="caret"></span>
                                </button>
                                <ul className="dropdown-menu" role="menu">
                                    <li><a href="/">Als gelesen markieren</a></li>
                                    <li><a href="/">Als ungelesen markieren</a></li>
                                    <li><a href="/">Als wichtig markieren</a></li>
                                    <li><a href="/">Zurückstellen</a></li>
                                    <li><a href="/">Zurückstellen</a></li>
                                    <li className="divider"></li>
                                    <li><a href="/">Archivieren</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="padding" style={{'paddingBottom': '30px'}}></div>

                    <div className="table-responsive">
                        <table className="table">
                            <tbody>
                            <tr>
                                <td className="action"><input type="checkbox"/></td>
                                <td className="action"><i className="fa fa-star-o"></i></td>
                                <td className="action"><i className="fa fa-bookmark-o"></i></td>
                                <td className="name"><a href="/">Software patch</a></td>
                                <td className="subject">
                                    <a href="/">Aktualisiere auf Adobe Acrobat v3.0.2</a><br/>
                                    <span className="exposed-devices">28 betroffene Geräte</span> <span
                                    className="badge badge-danger">Hohes Risiko</span>
                                </td>
                                <td className="time text-right">19:48</td>
                            </tr>
                            <tr>
                                <td className="action"><input type="checkbox"/></td>
                                <td className="action"><i className="fa fa-star-o"></i></td>
                                <td className="action"><i className="fa fa-bookmark"></i></td>
                                <td className="name"><a href="/">Software patch</a></td>
                                <td className="subject"><a href="/">Aktualisiere auf Wordpress v4.92</a><br/>
                                    <span className="exposed-devices">2 betroffene Geräte</span> <span
                                        className="badge badge-danger">Hohes Risiko</span></td>
                                <td className="time text-right">14:55</td>
                            </tr>
                            <tr className="read">
                                <td className="action"><input type="checkbox"/></td>
                                <td className="action"><i className="fa fa-star"></i></td>
                                <td className="action"><i className="fa fa-bookmark"></i></td>
                                <td className="name"><a href="/">Software patch</a></td>
                                <td className="subject"><a href="/">Aktualisiere auf Mozilla Firefox v79.0</a><br/>
                                    <span className="exposed-devices">4 betroffene Geräte</span> <span
                                        className="badge badge-secondary">Niedriges Risiko</span></td>
                                <td className="time text-right">03:15</td>

                            </tr>
                            <tr>
                                <td className="action"><input type="checkbox"/></td>
                                <td className="action"><i className="fa fa-star-o"></i></td>
                                <td className="action"><i className="fa fa-bookmark-o"></i></td>
                                <td className="name"><a href="/">Konfiguration</a></td>
                                <td className="subject"><a href="/">Deaktiviere Microsoft Office Markoss</a><br/>
                                    <span className="exposed-devices">1 betroffene Geräte</span> <span
                                        className="badge badge-danger">Hohes Risiko</span></td>
                                <td className="time text-right">2. Aug.</td>
                            </tr>
                            <tr className="read">
                                <td className="action"><input type="checkbox"/></td>
                                <td className="action"><i className="fa fa-star-o"></i></td>
                                <td className="action"><i className="fa fa-bookmark-o"></i></td>
                                <td className="name"><a href="/">Review</a></td>
                                <td className="subject"><a href="/">FreeBSD v11.3</a><br/>
                                    <span className="exposed-devices">14 betroffene Geräte</span> <span
                                        className="badge badge-danger">Hohes Risiko</span></td>
                                <td className="time text-right">2. Aug.</td>
                            </tr>
                            <tr className="read">
                                <td className="action"><input type="checkbox"/></td>
                                <td className="action"><i className="fa fa-star-o"></i></td>
                                <td className="action"><i className="fa fa-bookmark"></i></td>
                                <td className="name"><a href="/">Konfiguration</a></td>
                                <td className="subject"><a href="/">Backup einrichten</a><br/>
                                    <span className="exposed-devices">1 betroffene Geräte</span> <span
                                        className="badge badge-warning">Moderates Risiko</span></td>
                                <td className="time text-right">2. Aug.</td>
                            </tr>
                            <tr>
                                <td className="action"><input type="checkbox"/></td>
                                <td className="action"><i className="fa fa-star"></i></td>
                                <td className="action"><i className="fa fa-bookmark-o"></i></td>
                                <td className="name"><a href="/">Review</a></td>
                                <td className="subject"><a href="/">Redhat Enterprise Linux v8.0 </a><br/>
                                    <span className="exposed-devices">2 betroffene Geräte</span> <span
                                        className="badge badge-warning">Moderates Risiko</span></td>
                                <td className="time text-right">2. Aug.</td>
                            </tr>
                            <tr>
                                <td className="action"><input type="checkbox"/></td>
                                <td className="action"><i className="fa fa-star-o"></i></td>
                                <td className="action"><i className="fa fa-bookmark-o"></i></td>
                                <td className="name"><a href="/">Review</a></td>
                                <td className="subject"><a href="/">Oracle JDK v11.0.7</a><br/>
                                    <span className="exposed-devices">3 betroffene Geräte</span> <span
                                        className="badge badge-danger">Hohes Risiko</span></td>
                                <td className="time text-right">2. Aug.</td>
                            </tr>
                            <tr className="read">
                                <td className="action"><input type="checkbox"/></td>
                                <td className="action"><i className="fa fa-star"></i></td>
                                <td className="action"><i className="fa fa-bookmark"></i></td>
                                <td className="name"><a href="/">Review</a></td>
                                <td className="subject"><a href="/">Oracle JRE v1.8.0 </a><br/>
                                    <span className="exposed-devices">4 betroffene Geräte</span> <span
                                        className="badge badge-secondary">Niedriges Risiko</span></td>
                                <td className="time text-right">2. Aug.</td>
                            </tr>
                            <tr>
                                <td className="action"><input type="checkbox"/></td>
                                <td className="action"><i className="fa fa-star"></i></td>
                                <td className="action"><i className="fa fa-bookmark-o"></i></td>
                                <td className="name"><a href="/">Konfiguration</a></td>
                                <td className="subject"><a href="/">Aktiviere Firewall</a><br/>
                                    <span className="exposed-devices">1 betroffene Geräte</span> <span
                                        className="badge badge-danger">Hohes Risiko</span></td>
                                <td className="time text-right">2. Aug.</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>

                    <nav aria-label="Page navigation example">
                        <ul className="pagination">
                            <li className="page-item active"><a className="page-link" href="/">1</a></li>
                            <li className="page-item"><a className="page-link" href="/">2</a></li>
                            <li className="page-item"><a className="page-link" href="/">3</a></li>
                            <li className="page-item">
                                <a className="page-link" href="/" aria-label="Next">
                                    <span aria-hidden="true">&raquo;</span>
                                    <span className="sr-only">Next</span>
                                </a>
                            </li>
                        </ul>
                    </nav>
                </div>

            </div>
        </div>
    )
}
export default Tasks
