import React from 'react'
import { withRouter } from 'react-router'
import { AUTH_TOKEN } from '../../constants'
import jwt_decode from 'jwt-decode'

import styled from "styled-components"


// Components & CSS
import Logo from "./Logo/Logo"
// import Notification from "./Notification/Notification"
import Profile from "./Profile/Profile"
import './Topbar.css'
import Menu from './Menu/Menu'

const Styles = styled.nav`


`



const Topbar = () => {
  //const sidesWithoutHeader = ['/login', '/register', '/forgot-password']

  const token = localStorage.getItem(AUTH_TOKEN)
  const sub = token ? jwt_decode(token) : '';
  //let match = useRouteMatch();

  return (
    <Styles className="navbar header static-top"> 
      <Logo />
      <Menu />
      {/* <Notification /> */}
      <Profile sub={sub}/>
    </Styles>
     
  )
}

export default withRouter(Topbar)