/**
* This module provides several utilities common for functional programming.
*/




/**
Group an array of object values by a key.
Takes a array `col` holding several objects. Uses the attribute of the objects specified with `key`
and selects the value of the objects attribute `value`. Reduces the collection to an object in the form
`{key: [value1, value2, ...]}`.
*/
const groupBySelect = (col, key, value) => {
  return col.reduce((acc, current) => { (acc[current[key]] = acc[current[key]] || []).push(current[value]);
                                        return acc},
                    {});
}


/**
Group an array of object values by a key.
Takes a array `col` holding several objects. Uses the attribute of the objects specified with `key`.
Reduces the collection to an object in the form
`{key: [obj1, obj2, ...]}`.
*/
const groupBy = (col, key) => {
  return col.reduce((acc, current) => { (acc[current[key]] = acc[current[key]] || []).push(current);
                                        return acc},
                    {});
}

/**
Like `groupBy` but takes a function to select the collection key instead of the key itself.
This allows grouping by nested data.
*/
const groupByFn = (col, keyFn) => {
  // eslint-disable-next-line no-unused-vars
  return col.reduce((acc, current) => { let key =
                                        (acc[keyFn(current)] = acc[keyFn(current)] || []).push(current);
                                        return acc},
                    {});
}

const select = (col, keyFn) => {
  return col.map( (item) => { return keyFn(item) })
}


export { groupBySelect, groupBy,groupByFn, select };
