import React from 'react'
import { withRouter } from 'react-router'
import { AUTH_TOKEN } from '../../constants'
import jwt_decode from 'jwt-decode'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown  } from '@fortawesome/pro-solid-svg-icons'
import { Trans } from 'react-i18next';
import { NavLink, useRouteMatch } from 'react-router-dom'
import { SettingsIcon, DarkModeIcon, LogoutIcon, TenantsIcon } from "../SVG/common";
import './Topbar.css'

import { Dropdown } from "react-bootstrap"
import Logo from './Logo/Logo'

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  // eslint-disable-next-line jsx-a11y/anchor-is-valid
  <a
    href=""
    className="button-style"
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
    <FontAwesomeIcon icon={faCaretDown} className="width" />
  </a>
));



const Topbar = () => {
  const sidesWithoutHeader = ['/login', '/register', '/forgot-password']
  useRouteMatch();
  const token = localStorage.getItem(AUTH_TOKEN)
  const { sub } = token ? jwt_decode(token) : '';
  

  const onLogout = () => {
    localStorage.removeItem(AUTH_TOKEN);
    window.location.reload()
  }

  if (sidesWithoutHeader.includes(window.location.pathname)) return null;
  return (
    <nav className="navbar navbar-expand navbar-light bg-white header mb-4 static-top">
      <Logo />
        <Dropdown className="ml-auto">
            <Dropdown.Toggle as={CustomToggle} >
              {sub}
            </Dropdown.Toggle>

            <Dropdown.Menu >
               <NavLink to={`/tenants`} className='dropdown-item'><TenantsIcon /><Trans>Tenants</Trans></NavLink>
               <Dropdown.Divider />
               {/*<Dropdown.Item ><SettingsIcon /><Trans>Settings</Trans></Dropdown.Item>*/}
               {/*<Dropdown.Divider />*/}
               <Dropdown.Item onClick={onLogout}><LogoutIcon />Logout</Dropdown.Item>
             </Dropdown.Menu>
          </Dropdown>  
    </nav>
  )
}

export default withRouter(Topbar)