import React from "react"
import {Table} from "react-bootstrap"
import {Trans} from "react-i18next";
import {useQuery} from 'react-apollo'
import gql from 'graphql-tag'
import { useRouteMatch } from "react-router"
import {Link} from "react-router-dom";
import Loader from "../Common/Loader"
import { RiskLabel } from "../Common/RiskLabel"

const GET_VULN_FINDINGS = gql`
query VulnFindings($tenantId: String!){
    vulnerabilityFindingAggregations (tenantId: $tenantId) {
              findingId
              cve {
                  cveId
                  publishedAt
                  riskScore
                  severity
              }
              affectedProductsCount
              affectedDevicesCount
              devicesCount
              weakness
      }
  }
`
function convertDateToUtc(date) {
    const d = new Date(date);
    const utc = d.toUTCString();
    return utc.substring(4, 22) + utc.substring(25, 29);
}


const ProgressBar = (props) => {



    let {done, total} = props
    let percentDone = done / total * 100
    let percentUndone = (100 - percentDone)
    return (
        <div className="progress">
            <div className="progress-bar bg-success" style={{width: percentDone + "%"}}>
                {done}
            </div>
            <div className="progress-bar bg-danger" style={{width: percentUndone + "%"}}>
                {total - done}
            </div>
        </div>
    )
}
const Vulnerabilities = () => {
    const match = useRouteMatch();
    const tenantId = match.params.id
    // const [skipItems, setSkipItems] = useState(0);
    // const [filters, setFilters] = useState([
    //     // {field: "severity", values:["High", "Medium"]}
    // ]);

    const {loading, error, data} = useQuery(GET_VULN_FINDINGS, {
        variables: {tenantId: tenantId}
    })
    if(loading) return <Loader />;
    if(error) return <p>Error!!</p>;
    const vuln = data.vulnerabilityFindingAggregations


    // const options = {
    //     scales: {
    //         yAxes: [{
    //             ticks: {
    //                 beginAtZero: true,
    //                 min: 0,
    //                 max: 100,
    //             }
    //         }]
    //     }
    // }
    // const riskLevelData = {
    //     labels: ["August", "September", "October"],
    //     datasets: [
    //         {
    //             label: 'Unpatched Vulnerabilities',
    //             beginAtZero: true,
    //             fill: "1",
    //             lineTension: 0.1,
    //             backgroundColor: 'rgba(198,24,58, 0.2)',
    //             borderColor: 'rgb(198,24,58)',
    //             // borderCapStyle: 'butt',
    //             // borderDash: [],
    //             // borderDashOffset: 0.0,
    //             // borderJoinStyle: 'miter',
    //             // pointBorderColor: 'rgba(75,192,192,1)',
    //             // pointBackgroundColor: '#fff',
    //             // pointBorderWidth: 1,
    //             // pointHoverRadius: 5,
    //             // pointHoverBackgroundColor: 'rgba(75,192,192,1)',
    //             // pointHoverBorderColor: 'rgba(220,220,220,1)',
    //             // pointHoverBorderWidth: 2,
    //             // pointRadius: 1,
    //             // pointHitRadius: 10,
    //             data: [60, 55, 70],
    //         },
    //         {
    //             label: 'Patched Vulnerabilities',
    //             data: [0, 10, 20],
    //             fill: "origin",
    //             borderColor: "#43D743",
    //             backgroundColor: "rgba(67,215,67, 0.2)",
    //         }

    //     ],

    // };

    return (<>
    <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Trans>Reports</Trans></li>
                    <li className="breadcrumb-item active" aria-current="page"><Trans>Vulnerabilities</Trans></li>
                </ol>
            </nav>
            <div className="reports-vulnerabilities">
            </div>
        {/* <div className="row">

            <div className="col-12">
                <Line height={40} data={riskLevelData} options={options}/>
            </div>
        </div> */}
        <div className="row">
        <div className="col-2">
                    <div className="filter">
                        <div className="filter-header">
                            Filter
                        </div>
                        <div className="filter-body">
                            <strong>Risk</strong>
                            <ul>
                                <li>Critical</li>
                                <li>High</li>
                                <li>Medium</li>
                                <li>Low</li>
                                <li>None</li>
                            </ul>
                        </div>
                    </div>
                </div>
            <div className="col-10">
                <Table>
                    <thead>
                    <tr>
                        <th>
                            <Trans>CVE-ID</Trans>
                        </th>
                        <th>
                            <Trans>Severity</Trans>
                        </th>
                        <th>
                            <Trans>Weakness</Trans>
                        </th>
                        <th>
                            <Trans>Affected Devices</Trans>
                        </th>
                        <th>
                            <Trans>Progress</Trans>
                        </th>
                        <th>
                            <Trans>Publication Date</Trans>
                        </th>

                    </tr>
                    </thead>
                    <tbody>
                    {/* {[[5,6], [2,3], [4,4]].map((n) => (
                        <tr>
                            <td>
                                CVE-2020-12395
                            </td>
                            <td>
                                <Severity severity={"CRITICAL"}/>
                            </td>
                            <td>
                                Firefox
                            </td>
                            <td>
                                2020-04-28
                            </td>
                            <td>
                                {n[0]+n[1]}
                            </td>
                            <td>
                                <Trans>Remote Code Execution</Trans>
                            </td>
                            <td>
                                <Trans>Data Breach</Trans>
                            </td>
                            <td>
                                <ProgressBar done={n[0]} total={n[1]}/>
                            </td>
                            <td>
                                <FontAwesomeIcon icon={faSearch}/>
                            </td>
                        </tr>))
                    } */}
                    {vuln.sort((a, b) =>{
                        if(a.cve.riskScore < b.cve.riskScore){
                            return 1;
                        }
                        if(a.cve.riskScore > b.cve.riskScore){
                            return -1;
                        }
                        return 0;
                    }).map((v) => {
                        return (
                        <tr key={v.cve.cveId}>
                            <td>
                                <Link to={`/${tenantId}/findings/${v.findingId}/summary`} style={{'color': 'rgb(133, 135, 150)'}}>{v.cve.cveId}</Link>
                            </td>
                            <td>
                                <RiskLabel risk={v.cve.severity}/>
                            </td>
                            <td>
                                {v.weakness}
                            </td>
                            <td>
                                {v.affectedDevicesCount}
                            </td>
                            <td>
                                <ProgressBar done={v.devicesCount - v.affectedDevicesCount} total={v.devicesCount} />
                            </td>
                            <td>
                                {convertDateToUtc(v.cve.publishedAt).slice(0, 12)}
                            </td>
                        </tr>)
                    })}
                    </tbody>
                </Table>
            </div>
        </div>
    </>)
}

export default Vulnerabilities;
