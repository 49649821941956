import React from 'react';

const Logo = () => {
  return (
    <div>
      <svg width="180" height="36" viewBox="0 0 180 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16.9445 31.8269C16.9445 31.034 17.5948 30.3915 18.3962 30.3915C19.1989 30.3915 19.8492 31.034 19.8492 31.8269L19.8424 36H18.3921C13.3609 36 8.80461 33.9854 5.5081 30.7275C2.21024 27.4697 0.170837 22.9701 0.170837 18C0.170837 13.0299 2.21024 8.52893 5.5081 5.27246C8.80461 2.01463 13.3609 0 18.3921 0C23.4232 0 27.9782 2.01463 31.2761 5.27246C34.574 8.52893 36.6134 13.0299 36.6134 18C36.6134 18.7956 35.9603 19.4408 35.1549 19.4408C34.3495 19.4408 33.6964 18.7956 33.6964 18C33.6964 13.8242 31.9836 10.0449 29.2149 7.30859C26.4449 4.57358 22.6178 2.88016 18.3921 2.88016C14.165 2.88016 10.3393 4.57358 7.56927 7.30859C4.80064 10.0449 3.0864 13.8242 3.0864 18C3.0864 22.1744 4.80064 25.955 7.56927 28.6914C10.0182 31.1106 13.2943 32.7126 16.9418 33.0513L16.9445 31.8269Z" fill="white" />
        <path d="M24.5321 12.0851C23.9634 11.526 23.9607 10.6162 24.5266 10.0544C25.0926 9.49259 26.0137 9.4899 26.5824 10.049C27.6558 11.1067 28.5184 12.3687 29.1061 13.7678C29.6734 15.1239 29.9877 16.605 29.9877 18.1519C29.9877 21.3143 28.6898 24.1783 26.5919 26.2507C24.4926 28.3245 21.5934 29.6067 18.3921 29.6067C15.199 29.6067 12.3052 28.2909 10.2073 26.1876C8.09987 24.0762 6.79651 21.1691 6.79651 18.0054V16.5673L11.5134 16.5794C12.3161 16.5794 12.9664 17.2218 12.9664 18.0148C12.9664 18.8064 12.3161 19.4488 11.5134 19.4488L9.83316 19.4448C10.1393 21.2646 11.0195 22.9096 12.2794 24.1729C13.8467 25.7427 16.0071 26.7252 18.3921 26.7252C20.7893 26.7252 22.9593 25.7656 24.5294 24.2146C26.0994 22.6636 27.0721 20.5186 27.0721 18.1519C27.0721 16.984 26.8381 15.8765 26.4177 14.8712C25.9756 13.8189 25.3307 12.8727 24.5321 12.0851Z" fill="white" />
        <path d="M18.4411 21.1866C20.2316 21.1866 21.6831 19.7527 21.6831 17.9838C21.6831 16.215 20.2316 14.7811 18.4411 14.7811C16.6505 14.7811 15.199 16.215 15.199 17.9838C15.199 19.7527 16.6505 21.1866 18.4411 21.1866Z" fill="white" />
        <path d="M42.7982 20.5805C42.7982 25.7602 45.205 28.3648 49.9899 28.3648C52.1966 28.3648 53.9149 27.799 55.1761 26.666C56.4373 25.5626 57.0672 23.9485 57.0672 21.8545V21.6556H53.3707V21.8828C53.3707 22.8733 53.085 23.638 52.5395 24.2038C51.9667 24.7697 51.1368 25.0532 50.0756 25.0532C48.8729 25.0532 47.9845 24.685 47.4403 23.9767C46.8661 23.2698 46.5804 22.1086 46.5804 20.524V16.3066C46.5804 14.636 46.8661 13.4197 47.4103 12.6832C47.9559 11.9467 48.8144 11.5798 50.0184 11.5798C51.0783 11.5798 51.9095 11.862 52.5109 12.4561C53.085 13.0514 53.3707 13.8431 53.3707 14.8349V15.0043H57.1244V14.8067C57.1244 12.7396 56.4945 11.1551 55.2047 10.0221C53.9149 8.89047 52.1966 8.3233 50.047 8.3233C45.205 8.3233 42.7982 10.9279 42.7982 16.1077V20.5805Z" fill="white" />
        <path d="M60.1338 8.60687V28.1377H63.8874V8.60687H60.1338Z" fill="white" />
        <path d="M68.2996 26.7225C69.4751 27.7708 71.1362 28.3084 73.2573 28.3084C75.4055 28.3084 77.1252 27.799 78.415 26.7507C79.6748 25.7037 80.3061 24.2038 80.3061 22.251C80.3061 19.1935 78.615 17.3535 75.2055 16.7595L73.1131 16.3912C72.1675 16.2219 71.4804 15.9383 71.0505 15.5419C70.6206 15.1454 70.392 14.6078 70.392 13.9291C70.392 13.1643 70.6492 12.5421 71.1362 12.0892C71.6233 11.6645 72.3689 11.4373 73.343 11.4373C74.2885 11.4373 75.0327 11.6645 75.5212 12.0892C76.0082 12.5139 76.2939 13.2208 76.3511 14.1549H79.9619C79.819 12.3149 79.1592 10.8715 78.0422 9.85276C76.8966 8.83402 75.3484 8.3233 73.4001 8.3233C71.3947 8.3233 69.7893 8.86224 68.5866 9.90921C67.354 10.9562 66.7527 12.3996 66.7527 14.2113C66.7527 17.2406 68.5295 19.0523 72.1104 19.6464L73.9729 19.9582C74.8899 20.0993 75.5497 20.3829 75.9797 20.7794C76.4082 21.2027 76.6096 21.7981 76.6096 22.5615C76.6096 24.2885 75.5497 25.1379 73.4287 25.1379C72.3117 25.1379 71.4804 24.9108 70.9362 24.4578C70.3635 24.0345 70.0193 23.2698 69.8764 22.1946H66.3228C66.4656 24.1756 67.1255 25.6755 68.2996 26.7225Z" fill="white" />
        <path d="M82.7413 20.5805C82.7413 25.7602 85.0909 28.3648 89.8187 28.3648C92.0254 28.3648 93.8023 27.7425 95.1206 26.5249C96.409 25.3072 97.0688 23.3262 97.0688 20.5805V16.1077C97.0688 13.3619 96.4375 11.3526 95.1491 10.135C93.8594 8.91869 92.1397 8.3233 89.933 8.3233C85.1481 8.3233 82.7413 10.9279 82.7413 16.1077V20.5805ZM92.4839 23.9485C91.9397 24.685 91.0799 25.0532 89.933 25.0532C88.7303 25.0532 87.8704 24.685 87.3262 23.9767C86.782 23.2698 86.5235 22.1368 86.5235 20.524V16.3066C86.5235 14.6078 86.782 13.3915 87.3262 12.655C87.8704 11.9467 88.7303 11.5798 89.933 11.5798C91.0799 11.5798 91.9397 11.9467 92.4839 12.6832C93.0281 13.4479 93.3152 14.636 93.3152 16.3066V20.524C93.3152 22.0803 93.0281 23.2415 92.4839 23.9485Z" fill="white" />
        <path d="M101.395 26.8649C102.512 27.8272 104.089 28.3083 106.066 28.3083C108.157 28.3083 109.819 27.8272 111.024 26.8367C112.226 25.873 112.828 24.5156 112.828 22.7321C112.828 21.1745 112.398 19.9864 111.511 19.137C110.622 18.2876 109.361 17.7218 107.698 17.4382L105.406 17.0417C104.232 16.8441 103.343 16.4477 102.771 15.9101C102.169 15.3725 101.882 14.636 101.882 13.6737C101.882 12.5703 102.255 11.7209 103.028 11.069C103.773 10.4468 104.833 10.135 106.18 10.135C108.788 10.135 110.221 11.3526 110.449 13.7866H112.513C112.312 11.9467 111.653 10.5879 110.535 9.68339C109.418 8.80577 107.957 8.35284 106.209 8.35284C104.289 8.35284 102.771 8.83399 101.595 9.79628C100.421 10.7586 99.8483 12.0891 99.8483 13.8148C99.8483 15.316 100.277 16.4477 101.137 17.2688C101.997 18.09 103.229 18.6558 104.833 18.9099L107.069 19.2781C108.301 19.477 109.247 19.8721 109.848 20.4111C110.449 20.9487 110.765 21.7699 110.765 22.845C110.765 24.0345 110.392 24.939 109.618 25.5626C108.845 26.1848 107.698 26.4966 106.209 26.4966C103.343 26.4966 101.768 25.1943 101.509 22.6179H99.447C99.6184 24.4874 100.277 25.9026 101.395 26.8649Z" fill="white" />
        <path d="M117.928 26.5531C119.189 27.7707 120.88 28.3648 123 28.3648C125.035 28.3648 126.668 27.8272 127.872 26.7507C129.075 25.6755 129.676 24.232 129.676 22.3921V22.2792H127.642V22.3921C127.642 23.6662 127.241 24.6567 126.41 25.3637C125.58 26.1002 124.461 26.4684 123.029 26.4684C119.762 26.4684 118.129 24.6567 118.129 21.0051V15.9101C118.129 13.9008 118.559 12.4278 119.391 11.5515C120.221 10.6739 121.425 10.221 123 10.221C124.433 10.221 125.58 10.5879 126.41 11.3244C127.241 12.0609 127.642 13.0514 127.642 14.3255V14.4102H129.735V14.3255C129.735 13.1361 129.448 12.0891 128.903 11.1833C128.359 10.2774 127.556 9.56915 126.554 9.088C125.522 8.60686 124.347 8.35284 123 8.35284C120.88 8.35284 119.189 8.94689 117.928 10.135C116.668 11.3526 116.037 13.249 116.037 15.8254V20.8922C116.037 23.4673 116.668 25.3637 117.928 26.5531Z" fill="white" />
        <path d="M135.379 26.5531C136.611 27.7707 138.273 28.3648 140.364 28.3648C142.457 28.3648 144.118 27.7707 145.35 26.5531C146.583 25.3637 147.213 23.4673 147.213 20.8922V15.8254C147.213 13.249 146.612 11.3526 145.379 10.135C144.146 8.94689 142.485 8.35284 140.393 8.35284C138.302 8.35284 136.611 8.94689 135.379 10.135C134.147 11.3526 133.517 13.249 133.517 15.8254V20.8922C133.517 23.4673 134.147 25.3637 135.379 26.5531ZM145.121 21.0051C145.121 24.6567 143.545 26.4684 140.393 26.4684C138.817 26.4684 137.642 26.0437 136.84 25.1661C136.009 24.2885 135.608 22.9015 135.608 21.0051V15.9101C135.608 13.9008 136.009 12.456 136.812 11.5515C137.613 10.6739 138.817 10.221 140.393 10.221C143.545 10.221 145.121 12.1173 145.121 15.9101V21.0051Z" fill="white" />
        <path d="M163.059 10.1068C161.94 9.11624 160.422 8.60687 158.502 8.60687H151.396V28.1377H153.488V20.0711H158.502C160.422 20.0711 161.94 19.5617 163.059 18.5712C164.176 17.5807 164.72 16.1937 164.72 14.4384C164.72 12.5703 164.176 11.1268 163.059 10.1068ZM161.512 17.2689C160.766 17.8911 159.706 18.2029 158.331 18.2029H153.488V10.475H158.331C159.706 10.475 160.766 10.7868 161.512 11.4373C162.256 12.0891 162.629 13.1079 162.629 14.4384C162.629 15.7125 162.256 16.6466 161.512 17.2689Z" fill="white" />
        <path d="M170.022 10.475H179.936V8.60687H167.929V28.1377H179.936V26.2695H170.022V19.137H178.503V17.2689H170.022V10.475Z" fill="white" />
      </svg>

    </div>
  );
}

export default Logo;
