import React, { useState, useEffect, useCallback } from "react";
import gql from 'graphql-tag';
import { useQuery } from "react-apollo";
import { useRouteMatch, useLocation } from 'react-router-dom'

import './ReportBusiness.css'
import { FilterWidget, deriveFilterQuery, mergeFilterSets } from "../Filter/Filter";
import { PrintIcon, DownloadIcon } from "../SVG/common";
import LandscapeTD from "../Common/LandscapeTD";
import Loader from "../Common/Loader";


const GET_LANDSCAPE = gql`
query ReportsLandscape($tenantId: String!, $filters: [GenericFilterInput]){
  report(type: DEVICE_RISK_REPORT, tenantId: $tenantId, filters: $filters
  ) {
    __typename
    filterOptions {
      field
      values {
        name
        count
      }
    }
    ... on DeviceRiskReport {
      name
      businessDomains {
        id
        displayName
        devices {
          id
          displayName
          risk
        }
      }
    }
  }
}
`

/*

*/
const filterFromData = (data) => {
  let filter =
    data.map((category) => {
      return category.values.map((option) => {
        return {
          category: category.field,
          field: category.field,
          value: option.name,
          count: option.count,
          ident: (category.field + "_" + option.name).toLowerCase().replaceAll(' ', '_')
        }
      })
    })
  return [].concat.apply([], filter)
}


// import { Trans } from "react-i18next";
function useQueryParam() {
  return new URLSearchParams(useLocation().search)
}

const ReportBusiness = () => {

  const match = useRouteMatch();
  const tenantId = match.params.id

  let query = useQueryParam()

  // an ordered list (array) of all filters a user could set. These are all possible filters
  const [allAvailableFilter, setAllAvailableFilter] = useState([]);
  // an ordered list (array) of all filters a user can select. There are all filters that can be
  // set on the current query result. Subset of allAvailableFilter
  const [availableFilter, setAvailableFilter] = useState([]);

  // The filters that are currently active. The value in the set is the ident
  // of the filter in the availableFilter list
  const [activeFilter, setActiveFilter] = useState(new Set((query.get("filter") || " ").split(",")));
  activeFilter.delete(" ")

  let [searchString, setSearchString] = useState("");
  let triggerSearch = (searchString) => setSearchString(searchString);
  let [activePreselect, setActivePreselect] = useState(1);


  const { loading, error, data, refetch } = useQuery(GET_LANDSCAPE, {
    variables:
    {
      tenantId: tenantId,
      filters: deriveFilterQuery(availableFilter, activeFilter, searchString),
    },
    errorPolicy: 'all'
  });

  // Compose an update function for the filters
  const updateActiveFilter = useCallback((filters) => {
      setActiveFilter(filters);
      setTimeout(refetch, 5);
    },
    [availableFilter]);

  // The available filters are derived from the data received from the backend.
  // We have to wait until the devices list was received from the server. Only then we are capable
  // of creating the filers.
  useEffect(() => {
    if ((data !== undefined) && (allAvailableFilter.length === 0)) {
      setAllAvailableFilter(filterFromData(data.report.filterOptions))
    }
    if (data !== undefined) {
      setAvailableFilter(mergeFilterSets(allAvailableFilter, filterFromData(data.report.filterOptions)))
    }
  },
    [data]);

  if (loading && (data === undefined)) {
    return <Loader />;
  }
  if (error) {
    return <p>Error!!</p>;
  }

  let { businessDomains } = data.report
  // DUMMY DATA
  let availablePreselects = new Map([[1, {name: "Device Risk Report"}],
  // [2, {name: "Life of Brian"}],
  // [3, {name: "Knights who say Ni"}]
]);

  return (<>

    <div className="findings">
      <div className="row">
        {<div className="col-1-5">
          <FilterWidget
            availableFilter={availableFilter}
            activeFilter={activeFilter}
            updateActiveFilter={updateActiveFilter}
            triggerSearch={triggerSearch}
            availablePreselects={availablePreselects}
            activePreselect={activePreselect}
            updatePreselect={setActivePreselect}
            props={{showSearch: true,
              usePreselect: true}}
             />
        </div>}


        <div className={"col-sm-10"}>
          <div className="inline">
            <p className="landscape-title">{data.report.name}</p>

            <div className="margin">
              <button className="print"><PrintIcon />Print</button>
              <button className="print"><DownloadIcon />Download PDF</button>
            </div>
          </div>

          <div className="background-table insights">

            {businessDomains.map(domain => {
              return (
                <div key={domain.id} className="space-between">
                  <table style={{ borderCollapse: "separate", borderSpacing: "8px" }} className="landscape-table">
                    <thead>
                      <tr>
                        <th>{domain.displayName}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {domain.devices.map(device => {
                        return (
                          <tr key={device.id}>
                            <LandscapeTD value={device.displayName} risk={device.risk} deviceId={device.id}/>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
              )
            })}
          </div>


          {/* <div className="background-table insights">
            <table style={{borderCollapse: "separate", borderSpacing: "8px"}} className="landscape-table">
              <thead>
                <tr>
                  <th>Customer Relation</th>
                  <th>Human Resources</th>
                  <th>Finance</th>
                  <th>Development</th>
                  <th>Marketing</th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <LandscapeTD value="SAP ERP" risk="MEDIUM"/>
                  <LandscapeTD value="HR Plan" risk="MEDIUM"/>
                  <LandscapeTD value="SAP ERP" risk="MEDIUM"/>
                  <LandscapeTD value="Visual Studio" risk="LOW"/>
                  <LandscapeTD value="Microsoft Excel" risk="CRITICAL"/>
                </tr>
                <tr>
                  <LandscapeTD value="Allergo Horizon" risk="MEDIUM"/>
                  <LandscapeTD value="Payroll Germany" risk="CRITICAL"/>
                  <LandscapeTD value="Allergo Horizon" risk="LOW"/>
                  <LandscapeTD value="SAP Logistik" risk="MEDIUM"/>
                  <LandscapeTD value="Application 1" risk="MEDIUM"/>
                </tr>
                <tr>
                  <LandscapeTD value="IdeaIT" risk="MEDIUM"/>
                  <LandscapeTD value="Microsoft Word" risk="LOW"/>
                  <LandscapeTD value="Microsoft Excel" risk="HIGH"/>
                  <LandscapeTD value="Application DFGS" risk="MEDIUM"/>
                  <LandscapeTD />
                </tr>
                <tr>
                  <LandscapeTD value="Intercom Comm." risk="HIGH"/>
                  <LandscapeTD />
                  <LandscapeTD value="Payroll Germany" risk="MEDIUM"/>
                  <LandscapeTD value="Application SF" risk="CRITICAL"/>
                  <LandscapeTD />
                </tr>
                <tr>
                  <LandscapeTD value="SAP MM" risk="HIGH"/>
                  <LandscapeTD />
                  <LandscapeTD value="Application FGDS1" risk="MEDIUM"/>
                  <LandscapeTD value="Application JFS" risk="CRITICAL"/>
                  <LandscapeTD />
                </tr>
                <tr>
                  <LandscapeTD value="SAP SCM" risk="MEDIUM"/>
                  <LandscapeTD />
                  <LandscapeTD value="Allergo Horizon" risk="MEDIUM"/>
                  <LandscapeTD />
                  <LandscapeTD />
                </tr>
                <tr>
                  <LandscapeTD value="Application DFG" risk="MEDIUM"/>
                  <LandscapeTD />
                  <LandscapeTD value="Application 1" risk="MEDIUM"/>
                  <LandscapeTD />
                  <LandscapeTD />

                </tr>
              </tbody>
            </table>
          </div> */}


        </div>
      </div>
    </div>)
    {/*
    <div className="background-table insights">
        <p className="no-devices">
          This feature is not part of your purchased license.
          Please contact sales@cisoscope.com for further information.
        </p>
    </div> */}

  </>
  )
}




export default ReportBusiness
