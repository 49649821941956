import React from 'react'
import {Link} from 'react-router-dom'
import {Trans} from 'react-i18next';


const PageNotFound = () => {
        return(
            <div className="text-center">
                <div className="error mx-auto">404</div>
                <p className="lead text-gray-800 mb-5"><Trans>Page Not Found</Trans></p>
                <p className="text-gray-500 mb-0"><Trans>It looks like you found a glitch in the matrix! Agent Smith has already been informed.</Trans></p>
                <Link to="/">&larr; <Trans>Back to Home</Trans></Link>
            </div>
        )

}

export default PageNotFound