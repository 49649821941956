import React, { useState, useEffect, useCallback } from "react"

import './SelfAssesment.css';

import InsightsTable from "../Insights/InsightsTable"
import { NavLink, useRouteMatch, useLocation } from "react-router-dom";
import { useQuery } from "react-apollo";
import gql from 'graphql-tag';
import {Trans} from 'react-i18next';

import { FilterWidget, deriveFilterQuery } from "../Filter/Filter";
import { TableGroupRow } from "../DynamicTable/DynamicTable";
import { TableTextField, TableTextFieldWithExpand, TableTextFieldWithLink } from "../DynamicTable/Fields";
import Loader from "../Common/Loader";

const GET_ASSESMENTS = gql`
query Assesments($tenantId: String!, $filters: [GenericFilterInput]){
  report(
    tenantId: $tenantId
    type: SELF_ASSESSMENT
    filters: $filters
    )
  {
    __typename
    name
    description
    filterOptions {
      field
      values {
        name
        count
      }
    }
    ... on SelfAssessment {
      isoSections {
        name
        displayName
        passedRulesCount
        failedRulesCount
        isoChapters {
          name
          displayName
          passedRulesCount
          failedRulesCount
          isoSubChapters {
            name
            displayName
            passedRulesCount
            failedRulesCount
          }
        }
      }
    }
  }
}

`







const PassLabel = ({value, props}) => {
  return (
    <span className="pass">{value}</span>
  )
}

const FailLabel = ({value, props}) => {
  return (
    <span className="fail">{value}</span>
  )
}

const filterFromData = (data) => {
  let filter =
    data.map((category) => {
      return category.values.map((option) => {
        return {
          category: category.field,
          field: category.field,
          value: option.name,
          count: option.count,
          ident: (category.field + "_" + option.name).toLowerCase().replaceAll(' ', '_')
        }
      })
    })
  return [].concat.apply([], filter)
}

//
// let dummyIsoChapters = [{name: "A0.1",
//                          displayName:  "FOO",
//                          passedRulesCount: -2,
//                          failedRulesCount: -4},
//                         {name: "A0.2",
//                          displayName:  "BLAH",
//                          passedRulesCount: -6,
//                          failedRulesCount: -8}]
//
// let dummyIsoSubChapters = [{name: "A0.1.1",
//                             displayName:  "SUBB",
//                             passedRulesCount: -1,
//                             failedRulesCount: -3},
//                            {name: "A0.2.1",
//                             displayName:  "MORE",
//                             passedRulesCount: -5,
//                             failedRulesCount: -7}]

/**
* Transform the data from the backend to a collection that can be understood by the dynamic table.
*/
const assessmentsToTableData = (assessments) => {
  return assessments.map( (assessment) => {
    //console.log("current chapter: ", assessment)
    return {chapter: assessment.name,
            compliance: assessment.displayName,
            pass: assessment.passedRulesCount,
            fail: assessment.failedRulesCount,
            children: assessment.isoChapters.map((chapter) => { // assessment.isoChapters
              return {chapter: chapter.name,
                      compliance: chapter.displayName,
                      pass: chapter.passedRulesCount,
                      fail: chapter.failedRulesCount,
                      children: chapter.isoSubChapters.map((subChapter) => { //chapter.isoSubChapters
                        return {chapter: subChapter.name,
                                compliance: subChapter.displayName,
                                pass: subChapter.passedRulesCount,
                                fail: subChapter.failedRulesCount,}
                      })}})
           }
  })
}


const SelfAssesment = () => {
  const match = useRouteMatch();
  const tenantId = match.params.id;


  // const dummyFilter = [
  //   {category: "Chapters", field: "chapter", value: "1. Information", count: null, ident: "chapters_information"},
  //   {category: "Chapters", field: "chapter", value: "2. Vorgaben", count: null, ident: "chapters_vorgaben"}]

  // an ordered list (array) of all filters a user can select
  const [availableFilter, setAvailableFilter] = useState([]);

  // The filters that are currently active. The value in the set is the ident
  // of the filter in the availableFilter list
  const [activeFilter, setActiveFilter] = useState(new Set());

  // Compose an update function for the filters
  const updateActiveFilter = useCallback((filters) => { setActiveFilter(filters) },
    [availableFilter]);

  let [activePreselect, setActivePreselect] = useState(1);

  const { loading, error, data } = useQuery(GET_ASSESMENTS, {
    variables:
    {
      tenantId: tenantId,
      filters: deriveFilterQuery(availableFilter, activeFilter),
    },
    errorPolicy: 'all'
  });

  useEffect(() => {
    if ((data !== undefined) && (availableFilter.length === 0)) {
      setAvailableFilter(filterFromData(data.report.filterOptions))
    }
  },
    [data]);

  if (loading && (data === undefined)) {
    return <Loader />;
  }
  if (error) {
    return <p>Error!!</p>;
  }

  //let assesments = data.report.isoSections
  let tableData = assessmentsToTableData(data.report.isoSections);
  console.log("query data: ", data.report.isoSections)
  console.log("table data: ", tableData)

  const tableGroupFields = [{name:      "chapter",
                             component: "TextFieldWithExpand"},
                            {name:      "compliance",
                             component: "TableTextField"},
                            {name:      "pass",
                             component: "PassLabel"},
                            {name:      "fail",
                             component: "FailLabel"}];
  // Declare how the table data rows are rendered using a dynamic table
  const tableChildFields = [{name:      "chapter",
                             component: "TextFieldWithExpand"},
                            {name:      "compliance",
                             component: "TableTextField"},
                            {name:      "pass",
                             component: "PassLabel"},
                            {name:      "fail",
                             component: "FailLabel"}];
   // Declare how the table data rows are rendered using a dynamic table
   const tableGrandChildFields = [{name:      "chapter",
                                   component: "TableLinkField"},
                                  {name:      "compliance",
                                   component: "TableTextField"},
                                  {name:      "pass",
                                   component: "PassLabel"},
                                  {name:      "fail",
                                   component: "FailLabel"}];


   const renderWidget = (widget, value, props = {}) => {
     switch(widget) {
       case "TableLinkField":
         return (<TableTextFieldWithLink value={value} location={`/${tenantId}/insights/${props._assesmentId}`}/>);
       case "TableTextField":
         return (<TableTextField value={value} props={props}/>);
       case "TextFieldWithExpand":
       console.log("expand field should be ", props._enabled)
         return (<TableTextFieldWithExpand
                   value={value}
                   expanded={props.expanded}
                   toggleExpanded={props.toggleExpanded}
                   enabled={props._enabled}
                 />);
       case "PassLabel":
         return (<PassLabel value={value} />);
       case "FailLabel":
         return (<FailLabel value={value}/>)
       default:
         return (<TableTextField value={value} props={props}/>);
     }
   }

   const getWidgetFromName = (fieldName, fields) => {
     return fields.find((fd) => { if(fd.name === fieldName) { return fd.component }});
   }

   const selectWidget = (name, value, context, props = {}) => {
     if(name === "chapter") {
       props._assesmentId = context.chapter;
       props._enabled = (context.children !== null && context.children !== undefined && context.children.length !== 0);
     }
     return renderWidget(getWidgetFromName(name, tableGroupFields).component, value, props)
   };

   const selectChildWidget = (name, value, context, props = {}) => {
     if(name === "chapter") {
       props._assesmentId = context.chapter;
       props._enabled = (context.children !== null && context.children !== undefined && context.children.length !== 0);
     }
     return renderWidget(getWidgetFromName(name, tableChildFields).component, value, props)
   };

   const selectGrandChildWidget = (name, value, context, props = {}) => {
     console.log("context:", context)
     if(name === "chapter") {
       props._assesmentId = context.chapter;
       props._enabled = (context.children !== null && context.children !== undefined && context.children.length !== 0);
     }
     return renderWidget(getWidgetFromName(name, tableGrandChildFields).component, value, props)
   };


  // Set the preselects later from gql query
  let availablePreselects = new Map([[1, {name: "ISO 27001"}],
                                    //  [2, {name: "Life of Brian"}],
                                    //  [3, {name: "Knights who say Ni"}]
                                  ]);

  return (
    <div className="self-assesment">
      <div className="row">
        <div className="col-1-5">
          <FilterWidget
            availableFilter={availableFilter}
            activeFilter={activeFilter}
            updateActiveFilter={updateActiveFilter}
            availablePreselects={availablePreselects}
            activePreselect={activePreselect}
            updatePreselect={setActivePreselect}
            props={{showSearch: false,
                    usePreselect: true}}
          />
        </div>

        <div className={tableData.length === 0 ? "col-sm-12" : "col-sm-10"}>
          <div className="background-table insights">
            <table className="table-in-card ">
              <colgroup>
                <col style={{width: "10%"}}/>
                <col style={{width: "40%"}}/>
                <col style={{width: "10%"}}/>
                <col style={{width: "10%"}}/>
              </colgroup>
              <thead>
                <tr>
                  <th className="alignment">
                    <Trans>Chapter</Trans>
                  </th>
                  <th className="alignment"><Trans>Compliance</Trans></th>
                  <th className="alignment"><Trans>Pass</Trans></th>
                  <th className="alignment"><Trans>Fail</Trans></th>
                </tr>
              </thead>
                {tableData.map((a) =>{
                  return <TableGroupRow
                    key={a.name}
                    row={a}
                    fields={tableGroupFields}
                    childrenFields={tableChildFields}
                    grandChildrenFields={tableGrandChildFields}
                    widgetFn={selectWidget}
                    childrenWidgetFn={selectChildWidget}
                    grandChildrenWidgetFn={selectGrandChildWidget}
                  />}
                )}
            </table>
          </div>

        </div>
      </div>
    </div>
  );
}








const SelfAssesmentOld = () => {
  return (
    <div className="background-table insights">
      <InsightsTable>
        <p className="no-devices">This feature is not part of your purchased license.
        Please contact sales@cisoscope.com for further information.</p>
      </InsightsTable>
    </div>
  );
}













export default SelfAssesment;
