import React from 'react'
import gql from 'graphql-tag'
import {useQuery} from 'react-apollo'
import {Link, useRouteMatch} from 'react-router-dom'
import {Trans} from 'react-i18next'
import './ArchitectureDomainsReport.css'
import Loader from '../Common/Loader'

const GET_ARCHITECTURE_DOMAIN_REPORT = gql`
query ArchitectureDomainReportQuery ($tenantId: String!){
      architectureDomainReport (tenantId: $tenantId) {
        architectureDomains {
          name
          devicesCount
          devices {
            id
            displayName
            hostname
            maxSeverity
            maxRiskScore
          }
        }
      }
    }

`

const ArchitectureDomain = ({architectureDomain}) => {
    const match = useRouteMatch();
    const tenantId = match.params.id
    return (
        <div className="card">
            <div
                className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                <h6 className="m-0 font-weight-bold text-primary">{architectureDomain.name} ({architectureDomain.devicesCount})</h6>
            </div>
            <div className="card-body">
                <div className="row">
                    {architectureDomain.devices.map((device, index) => {
                        return (
                            <Link key={index} to={`/${tenantId}/devices/${device.id}/summary`}>
                                <div className={device.maxSeverity.toLowerCase() + " business_application"}>
                                    <div>{device.displayName}</div>
                                </div>
                            </Link>
                        )
                    })}

                </div>
            </div>
        </div>
    )
}

const ArchitectureDomainsReport = () => {
    const match = useRouteMatch();
    const tenantId = match.params.id

    const {loading, error, data} = useQuery(GET_ARCHITECTURE_DOMAIN_REPORT, {
        variables: {tenantId: tenantId},
        errorPolicy: 'all'
    });
    if (loading) return <>
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
                <li className="breadcrumb-item"><Trans>Reports</Trans></li>
                <li className="breadcrumb-item active" aria-current="page"><Trans>Architecture Domains</Trans></li>
            </ol>
        </nav>
        <Loader /></>;
    if (error) return <>
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
                <li className="breadcrumb-item"><Trans>Reports</Trans></li>
                <li className="breadcrumb-item active" aria-current="page"><Trans>Architecture Domains</Trans></li>
            </ol>
        </nav>
        <p>Error!!</p></>;
    const {architectureDomainReport} = data;
    const architectureDomains = architectureDomainReport.architectureDomains.filter(d => d.devicesCount > 0);
    architectureDomains.sort((a, b) => b.devicesCount - a.devicesCount);
    return (
        <>
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Trans>Reports</Trans></li>
                    <li className="breadcrumb-item active" aria-current="page"><Trans>Architecture Domains</Trans></li>
                </ol>
            </nav>
            <div className="reports-architecture-domains">
                <div className="row">
                    <div className="col-2">
                        <div className="filter">
                            <div className="filter-header">
                                Filter
                            </div>
                            <div className="filter-body">
                                <strong><Trans>Risk</Trans></strong>
                                <ul>
                                    <li><Trans>Critical</Trans></li>
                                    <li><Trans>High</Trans></li>
                                    <li><Trans>Medium</Trans></li>
                                    <li><Trans>Low</Trans></li>
                                    <li><Trans>None</Trans></li>
                                </ul>

                                <strong><Trans>Architecture Domains</Trans></strong>
                                <ul>
                                    {architectureDomains.map(d => {
                                        return (<li key={d.name}>{d.name} ({d.devicesCount})</li>)
                                    })}
                                </ul>

                            </div>
                        </div>
                    </div>
                    <div className="application-content col-9">
                        <div className="row">
                            {architectureDomains.map((d, index) =>
                                <div key={index} className="col-6" style={{paddingBottom: '25px'}}><ArchitectureDomain
                                    architectureDomain={d}/></div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>)
}

export default ArchitectureDomainsReport
