import React, { useState, useEffect } from "react"
// import { Trans } from 'react-i18next'
import { useTranslation } from "react-i18next";
import { Doughnut, Line } from 'react-chartjs-2';
import './Dashboard.css'
import gql from 'graphql-tag'
import convertDateToUtc_Short from "../Common/helpers"
import InsightsTable from "../Insights/InsightsTable"

import { NavLink, useRouteMatch } from "react-router-dom"
import { useQuery } from 'react-apollo'
import {
  COLOR_RISK_CRITICAL,
  COLOR_RISK_HIGH,
  COLOR_RISK_LOW,
  COLOR_RISK_MEDIUM,
  COLOR_RISK_UNKOWN
} from "../../constants";
import RiskMatrix from "../Common/RiskMatrix";
import Loader from '../Common/Loader';
import CardStats from './CardStats';
import { VulnerabilityLabelSVG, ComplicanceViolationLabelSVG, DeviceSVG, NextArrowSVG, ScanSVG, LetterIcon } from '../SVG/common';
import Title from './Title';




const GET_ASSETS = gql`
query TenantDashboardQuery($tenantId: String!){
  report(tenantId: $tenantId, type: DASHBOARD) {
    __typename
    name
    description
    ... on Dashboard {
      vulnerabilityInsightsCount
      complianceInsightsCount
      affectedDevicesCount
      devicesTotalCount
      scanCoverage24Hours
      lastCompleteScanAt
      lastInsights (limit: 6) {
        insightType
        id
        displayName
        risk
        updatedAt
        firstSeenAt
      }
      riskMatrix {
        likelihood
        impact
        count
      }
      riskStatistic {
        label
        count
      }
      riskLevelHistory {
        createdAt
        riskLevel
      }
  	}
  }
}
`

const Dashboard = () => {
  const { t } = useTranslation();
  let match = useRouteMatch();
  let tenantId = match.params.id

  let [breakpoint, setBreakpoint] = useState(!window.matchMedia("(min-width: 1521px)").matches);

  useEffect(() => {
    let handler = () => setBreakpoint(!window.matchMedia("(min-width: 1521px)").matches);
    window.matchMedia("(min-width: 1521px)").addListener(handler)
    return () => window.matchMedia("(min-width: 1521px)").removeListener(handler)
  })

  //Risk Level Graph options
  const options = {

    bezierCurve: true,
    scales: {

      yAxes: [{
        ticks: {
          beginAtZero: true,
          min: 0,
          max: 10,
          maxTicksLimit: 8,
          padding: 15,
          fontFamily: "Inter-2",
          fontSize: 14,
          fontWeight: "600",
          fontColor: "#A6ADB6",
        },
        gridLines: {
          drawTicks: false,
          display: false
        },

      }],
      xAxes: [{
        gridLines: {
          drawTicks: false,
          display: false,
          zeroLineColor: "transparent",
        },
        ticks: {
          padding: 15,
          fontFamily: "Inter-2",
          fontSize: 14,
          fontWeight: "600",
          fontColor: "#A6ADB6",
        },
      }],
    },
    legend: {
      display: false,
    },
    hover: {
      onHover: function (e) {
        var point = this.getElementAtEvent(e);
        if (point.length) e.target.style.cursor = 'pointer';
        else e.target.style.cursor = 'default';
      }
    }
  }
  const riskLevelData = (canvas) => {
    const ctx = canvas.getContext("2d");
    const gradient = ctx.createLinearGradient(0, 0, 0, 150);
    gradient.addColorStop(0, "rgba(67, 135, 211, 1)");
    gradient.addColorStop(1, "rgba(234, 244, 255, 1)");

    return {
      labels: riskLevelHistory.map(r => convertDateToUtc_Short(r.createdAt).slice(0,6)),
      datasets: [
        {
          label: t('Risk Level'),
          beginAtZero: true,
          fill: true,
          backgroundColor: gradient,
          borderColor: '#0F57A6',
          pointBorderColor: 'rgba(255,255,255,1)',
          pointBackgroundColor: '#0F57A6',
          pointBorderWidth: 1,
          pointHoverRadius: 6,
          pointHoverBackgroundColor: '#0F57A6',
          pointHoverBorderColor: 'rgba(255,255,255,1)',
          pointHoverBorderWidth: 2,
          pointRadius: 3,
          pointHitRadius: 10,
          data: riskLevelHistory.map(r => r.riskLevel),
        }
      ],

    };
  }

  //gql data fetch
  const { loading, error, data } = useQuery(GET_ASSETS, {
    fetchPolicy: "cache-and-network",
    // pollInterval: 12000,
    variables: { tenantId: tenantId }
  });
  if (loading) return <Loader />;
  if (error) return <p>Error!!</p>;
  const {
    lastInsights,
    complianceInsightsCount,
    vulnerabilityInsightsCount,
    riskStatistic,
    scanCoverage24Hours,
    affectedDevicesCount,
    devicesTotalCount,
    riskMatrix,
    riskLevelHistory
  } = data.report

  //const lastCompleteScanAtFormatted = new Date(Date.parse(scanCoverage24Hours + 'Z')).toUTCString().slice(4, 22) + " " + new Date(Date.parse(scanCoverage24Hours + 'Z')).toUTCString().slice(25, 29)
  const removeUnwantedData = (stats) => {
    return stats.filter((s) => s.label !== "TOTAL" && s.label !== "UNKNOWN")
  }
  const vulnerabilitiesBySeverityData = {
    labels: removeUnwantedData(riskStatistic).map(a => t(a.label)),
    datasets: [{
      data: removeUnwantedData(riskStatistic).map(a => a.count),
      backgroundColor: [
        COLOR_RISK_CRITICAL,
        COLOR_RISK_HIGH,
        COLOR_RISK_MEDIUM,
        COLOR_RISK_LOW,
        COLOR_RISK_UNKOWN,
      ],
    }],
  };

  const vulnOptions = {
    responsive: false,
    aspectRatio: 1,
    cutoutPercentage: 70,
    legend:{
      position: "bottom",
    },


  }


const convertToPercentage = float =>{
  // convert floats into percentages for scan coverage
  return Math.floor(float*100)
}
return (
  <>
    <div className="dashboard">
      {/**Cards with overview details on Vuln, Devices, Compliance and Scan Coverage */}
      <div className="row">
        <div className="dashboard-card">
          <NavLink to={`/${tenantId}/insights?filter=insight_type_vulnerability`}>
            <CardStats svg={<LetterIcon letter="V" />} heading={"Vulnerabilities"} amount={vulnerabilityInsightsCount} />
          </NavLink>
        </div>
        <div className="dashboard-card">
          <NavLink to={`/${tenantId}/insights?filter=insight_type_compliance`}>
            <CardStats svg={<LetterIcon letter="C" />} heading={"Compliance Violations"} amount={complianceInsightsCount} />
          </NavLink>
        </div>
        {breakpoint && <div className="card-breaker"></div>}
        <div className="dashboard-card">
          <NavLink to={`/${tenantId}/devices?filter=risk_low,risk_medium,risk_high,risk_critical`}>
            <CardStats svg={<DeviceSVG />} heading={"Affected Devices"} amount={affectedDevicesCount} total={devicesTotalCount} />
          </NavLink>
        </div>
        <div className="dashboard-card">
        <NavLink to={`/${tenantId}/devices?filter=state_missing`}>
          <CardStats svg={<ScanSVG />} heading={"Scan Coverage"} amount={convertToPercentage(scanCoverage24Hours)} />
        </NavLink>
        </div>
      </div>
      {/**Recent 6 Insights */}
      <Title title={"New Insights"} />
      <div className="background-table">
        <InsightsTable insights={lastInsights}>
        </InsightsTable>
        <div >
          {lastInsights.length !== 0 ? 
          <p className="table-link"><NavLink to={`/${tenantId}/insights`}>Show all Insights <NextArrowSVG /></NavLink></p>
           : ""}
        </div>
      </div>

      {/**Risk Matrix Data Visualization */}
      <div className="row">
        <div className="col-xl-4 col-lg-4">
          <Title title={"Risk Matrix"} />
          <div className="card data-in-card">
            {lastInsights.length !== 0 ? <><div className="card-body flex">
              <span className="likelihood">Likelihood</span>
              <RiskMatrix data={riskMatrix} />
            </div>
            <p className="impact">Impact</p> </>:
            <p className="no-devices">No Devices added yet.</p>
            }

          </div>
        </div>

        {/**Risk Level Data Visualization */}
        <div className="col-xl-4 col-lg-4">
          <Title title={"Risk Level"} />
          <div className="card data-in-card">
            {lastInsights.length !== 0 ? <div className="card-body m-auto">
              <Line data={riskLevelData} options={options} width={370} height={200} />
            </div> : <p className="no-devices">No Devices added yet.</p>}
          </div>
        </div>

        {/**Problem with Custom Legend */}
        <div className="col-xl-4 col-lg-4">
          <Title title={"Vulnerabilities by Severity"} />
          <div className="card data-in-card" >
            {lastInsights.length !== 0 ? <><div style={{marginTop: "1.5rem"}} className="m-auto">
            <Doughnut data={vulnerabilitiesBySeverityData} options={vulnOptions} width={370} height={200}/>
            </div> </>: <p className="no-devices">No Devices added yet.</p>}
          </div>
        </div>
      </div>
    </div>
  </>
)}

export default Dashboard;
