import Topbar from "../components/Topbar/Topbar"
import React from 'react'
import Helmet from "react-helmet"
import { Container } from "react-bootstrap"
// import styled from 'styled-components'
// import Filter from "../components/Filter/Filter"



const LayoutWithSidebar = (props) => {


  return (<>
    <Helmet>
      <title>CISOSCOPE</title>
      <link rel="canonical" href="https://app.cisoscope.com" />
    </Helmet>
      <Container>
      <Topbar />
        <div id="content-wrapper">
          <div id="content align-center">
            {React.createElement(props.children, props)}
          </div>
      </div>
      </Container>

    
  </>
  )

}

export default LayoutWithSidebar


