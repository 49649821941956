
import React, {useState} from "react"
import { RiskLabel } from '../Common/RiskLabel';
import { VulnerabilityLabelSVG, LetterIcon } from "../SVG/common"
// import './Insights.css'
//import { Trans } from "react-i18next";
import { NavLink, useRouteMatch } from "react-router-dom";
import convertDateToUtc_Short from "../Common/helpers"
import DomainWidget from "../Common/DomainWidget";



const DevicesTable = ({devices, tenants, children, activeFilter }) => {
  //getting Tenant ID from the URL
  const match = useRouteMatch();
  const tenantId = match.params.id

  return (devices.length !== 0 ?
    <table className="table-in-card">
      <thead>
        <tr>
          <th className="width-devices alignment">Device</th>
          <th className="width-os alignment">Operating System</th>
          <th className="width-domain alignment">Business Domain</th>
          <th className="width-scan alignment">Last Scan</th>
          <th className="width-risk-devices alignment">Risk</th>
        </tr>
      </thead>
      <tbody>
        {devices.map((ele, i) => {
          return (
            <tr key={i} >
              <td className="alignment"> <NavLink to={`/${tenantId}/devices/${ele.id}`}><LetterIcon letter="D" value={23}/><span style={{ marginLeft: "0.5rem" }}>{ele.displayName}</span></NavLink></td>
              <td className="alignment"><NavLink to={`/${tenantId}/devices/${ele.id}`}>{ele.operatingSystemName}</NavLink></td>
              <td className="alignment"><DomainWidget businessDomainNames={ele.businessDomainNames} activeFilter={activeFilter}/></td>  
              <td className="alignment"><NavLink to={`/${tenantId}/devices/${ele.id}`}>{convertDateToUtc_Short(ele.lastScannedAt)}</NavLink></td>
              <td className="alignment"><NavLink to={`/${tenantId}/insights/${ele.id}`}><RiskLabel risk={ele.risk} /></NavLink></td>
            </tr>
          )
        })}
      </tbody>
    </table>
    : <><table className="table-in-card">
    <thead>
      <tr>
        <th className="width-devices alignment">Device</th>
        <th className="width-os alignment">Operating System</th>
        <th className="width-domain alignment">Business Domain</th>
        <th className="width-scan alignment">Last Scan</th>
        <th className="width-risk-devices alignment">Risk</th>
      </tr>
    </thead>
    </table> <p className="no-devices">No Devices found</p></>)

}
//<p className="no-devices">No Devices added yet.</p>
export default DevicesTable;
