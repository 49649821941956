import React from 'react';
import styled from "styled-components"
import { NavLink, useRouteMatch } from "react-router-dom"

const Styles = styled.div`
  div {
    border-radius: 92px;
    width: 40px;
    height: 24px;
    margin: auto;
    color: white;
  }
  .low{
    background: #FCBF24; 
  }
  .medium {
    background: #FF841E;
  }
  .high{
    background: #DA4347;
  }
  .critical{
    background: #AD45B9;
  }
`

const RiskMatrixLabel = ({likelihood, impact, value}) => {
  let match = useRouteMatch();
  let tenantId = match.params.id
  let riskColor = ""
  switch(true){
    case likelihood==="critical" && impact==="critical":
    case likelihood==="high" && impact ==="critical":
    case likelihood==="critical" && impact === "high":
      riskColor = "critical";
      break;
    case likelihood==="high" && impact==="high":
    case likelihood==="critical" && impact==="medium":
    case likelihood==="medium" && impact==="critical":
    case likelihood==="high" && impact==="medium":
    case likelihood==="medium" && impact ==="high":
      riskColor = "high"
      break;
    // case likelihood==="medium"  && impact==="medium": 
    // case likelihood==="medium"  && impact==="high":
    // case likelihood==="medium"  && impact==="critical":
    // case likelihood==="high"    && impact==="medium":
    // case likelihood==="critical"&& impact==="medium":
    //   riskColor = "medium"
    //   break;
    case likelihood==="low" && impact==="low":
    case likelihood==="low" && impact==="medium":
    case likelihood==="medium" && impact==="low":
      riskColor="low"
      break;
    default: riskColor = "medium"
  }
  return (
    <Styles>
      <NavLink to={`/${tenantId}/insights?filter=risk_${riskColor}`}>
        <div className={riskColor}>{value}</div>
      </NavLink>
    </Styles>
  );
}

export default RiskMatrixLabel;


//<NavLink to={`/${tenantId}/insights?filter=insight_type_vulnerability`}></NavLink>