/**
* Create a hash value from a string
* Inspired from https://stackoverflow.com/a/7616484/5841420
*/
const stringHash = (s) => {
  var hash = 0, i, chr;
  if (s.length === 0) return hash;
  for (i = 0; i < s.length; i++) {
    chr   = s.charCodeAt(i);
    hash  = ((hash << 5) - hash) + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
};




export { stringHash }
