import React, { useState, useRef } from "react"
import './Filter.css'
import {Trans, useTranslation} from "react-i18next";
import { groupBy, groupBySelect } from '../../utils/Functionals';
import { useOnClickOutsideHook } from '../../utils/hooks';
import { RiskLabel, riskToInt } from "../Common/RiskLabel";
import { VulnerabilityLabelSVG, ComplicanceViolationLabelSVG, ThreatLabelSVG, IncidentLabelSVG,
         DropdownArrowSVG, BracketArrowRightSVG } from "../SVG/common"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/pro-regular-svg-icons";
import FilterCount from "../Common/FilterCount";


const sanitizeSearchString = (string) => {
  return string.match(/(?:[^\s"]+|"[^"]*")+/g).map( (match) => {
    return match.replaceAll("\"", "")
  })

}

// Derive the query fragment specifying the active filters from the component state.
const deriveFilterQuery = (availableFilter, activeFilter, searchString) => {
  const filtered = availableFilter.filter((filter) => { return activeFilter.has(filter.ident) });
  const grouped = groupBySelect(filtered, "field", "value");
  let filterQuery = Object.keys(grouped).map((key) => { return {field: key, values: grouped[key]} });
  if(searchString)
    filterQuery.push({field: "SEARCH", values: sanitizeSearchString(searchString)});
  return filterQuery;
}


/**
* Merge two lists of filter objects. Takes a list of `allFilter` available to the use case and a
* list of filter objects that can be applied to the currently active data set. The merge process
* will set filter count to 0 for not applicable filter.
*/
const mergeFilterSets = (allFilter, selectableFilter) => {
  if (allFilter.length === 0) {
    return selectableFilter
  } else {
    return allFilter.map(filter => {
      return {...filter, ...(selectableFilter.filter(obj => obj.ident === filter.ident)[0] || {count: 0})}
    })

  }
}


const FilterOption = ({option, active, toggleFilter, hideFilter}) => {
  const type = () => {
    switch(option.value) {
      case "VULNERABILITY": return <span style={{marginRight: ".5rem"}}> <VulnerabilityLabelSVG value={23}/></span>
      case "COMPLIANCE": return <span style={{marginRight: ".5rem"}}> <ComplicanceViolationLabelSVG value={23}/></span>
      case "THREAT": return <span style={{marginRight: ".5rem"}}> <ThreatLabelSVG value={23}/></span>
      case "INCIDENT": return <span style={{marginRight: ".5rem"}}> <IncidentLabelSVG value={23}/></span>
      default:
    }}

  return (
    <li onClick={(e) => {toggleFilter(option.ident)}} >
      <input type="checkbox"
             className="checkbox"
             checked={active}
             onChange={(e) => null}
      >
        {/* <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3 7.66667L6.52941 11L15 3" stroke="#DBE1E8" strokeWidth="3.4" strokeMiterlimit="10" strokeLinecap="square"/>
      </svg> */}
      </input>
      {type()}
      {(option.category === "RISK") ? <RiskLabel risk={option.value} /> : <span className="filter-option-text"><Trans>{option.value}</Trans></span>}
      {(null !== option.count) &&
        <div style={{display: "flex", alignSelf: "center", marginLeft: "auto"}}>
          {<FilterCount count={option.count}/>}
        </div>}
    </li>
  );
}


const FilterDropdown = ({options, active, onUpdate}) => {
  let [expanded, setExpanded] = useState(false);
  let toggleExpand = () => { console.log("Clicky"); setExpanded(!expanded) };
  let onOption = (id) => { onUpdate(id); setExpanded(false);}

  let preselectRef = useRef(null);
  useOnClickOutsideHook(preselectRef, () => setExpanded(false) );


  return (
    <div ref={preselectRef} className="filter-dropdown-container" >
      <div className="filter-dropdown-select"
           onClick={toggleExpand}>
        <span className="dropdown-text">
          {options.get(active).name}
        </span>
        <span>
          <DropdownArrowSVG
            color="#808C9C"
          />
        </span>
      </div>
      { expanded &&
        <div className="filter-options-container">
          {Array.from(options).map(([k, o]) => {
            return (
            <div className="option"
                 key={"option-"+k}
                 onClick={() => onOption(k)}>
              <span className="title">{o.name}</span>
              <span className="subtitle">Predefined</span>
            </div>
          )})}
        </div>}
    </div>
  );
}


const FilterGroup = ({ options, activeFilter, toggleFilter}) => {
  // filter collapsed state
  let [collapsed, setCollapsed] = useState(false);


  return (
    <div style={{marginTop: "1.5rem"}}>
      <div className="filter-group-label"
           onClick={() => setCollapsed(!collapsed)}>
        <div className={collapsed ? "filter-collapsed" : "filter-not-collapsed"}>
          <BracketArrowRightSVG width={16} height={12} color={"#0D276B"}/>
        </div>
        <h3 style={{marginLeft: "0.5rem"}}><Trans>{options[0].category}</Trans></h3>
      </div>
      {!collapsed &&
      <ul>
      {options.map( (option) => { return (
        <FilterOption
          key={option.ident}
          option={option}
          active={ (activeFilter.has(option.ident)) ? true : false }
          toggleFilter={toggleFilter}
        />
        )})}
      </ul>}
    </div>
  )
}

const filterDefaultProps = {
  showSearch: true,
  usePreselect: false}

const FilterWidget = ({availableFilter, activeFilter, updateActiveFilter,
                       triggerSearch, setSkipItems,
                       availablePreselects, activePreselect, updatePreselect,
                       props = filterDefaultProps}) => {
    const { t } = useTranslation();


    // sort the options within a filter group
    // Can use special ordering (e.g. for the Risk) otherwise uses lexicographic ordering
    const sortFilterOptions = (options) => {
      if (undefined === options || null === options) return options;
      if(options[0].field === "RISK") {
        return options.sort((first, second) => {
          if(riskToInt(first.value) > riskToInt(second.value)) return -1;
          if(riskToInt(first.value) < riskToInt(second.value)) return 1;
          if(riskToInt(first.value) === riskToInt(second.value)) return 0;})
      } else {
        return options.sort((first, second) => {
          if(first.value.toLowerCase() > second.value.toLowerCase()) return 1;
          if(first.value.toLowerCase() < second.value.toLowerCase()) return -1;
          if(first.value.toLowerCase() === second.value.toLowerCase()) return 0;})
      }
    }

    // Enable a single filter by adding its ident to the active set.
    // **Note**:
    //  We have to do this hacky copy and return stuff because JS is a stupid language!
    //  A JS Set type will always perform an `add` method in place thus a mutation is made and the
    //  original reference created with the `React.useState()` function will not trigger a re-rendering.
    //  This is because the internal reference of the state will change when we call the `add` method and
    //  the diff between the new value we pass to the set function will nil.
    const enableFilter = (ident) => { let copy = new Set(activeFilter);
                                      copy.add(ident);
                                      setSkipItems && setSkipItems(0)
                                      updateActiveFilter(copy); };
    // disable a single filter by removing it from the active filter set
    // **Note**: Same stupid JS hack as in the `enableFilter` function.
    const disableFilter = (ident) => { let copy = new Set(activeFilter);
                                       copy.delete(ident);
                                       setSkipItems && setSkipItems(0)
                                       updateActiveFilter(copy) };
    // toggle a filter
    const toggleFilter = (ident) => {
      (activeFilter.has(ident) ? disableFilter(ident) : enableFilter(ident))
      setSkipItems && setSkipItems(0)
    };

    // Searchbar
    let [searchString, setSearchString] = useState("");
    let updateSearchString = (e) => setSearchString(e.target.value)

    // reset all active filter by cleaning the active set
    const resetFilter = () => {
      setSkipItems && setSkipItems(0)
      updateActiveFilter(new Set())
      setSearchString("")
      triggerSearch()
    };

    return (
      <div className="filter">
        <div id="filter-header" >
          { (props.showSearch) &&
            <div className="filter-searchbar-container" >
              <input id="filter-search"
                     placeholder={t("Search")}
                     type="text"
                     autoComplete="off"
                     value={searchString}
                     onChange={updateSearchString}
                     onKeyPress={(e) => { if( (e.key || e.code) === "Enter" ) triggerSearch(e.target.value)}}>
              </input>
              <span className="input-icon"
                    onClick={(e) => triggerSearch(document.getElementById("filter-search").value)}>
                <FontAwesomeIcon icon={faSearch}/></span>
            </div>}
        </div>
        <div className="filter-body">
        { (props.usePreselect) &&
            <FilterDropdown
              options={availablePreselects}
              active={activePreselect}
              onUpdate={updatePreselect}
            />
            }
          {Object.entries(groupBy(availableFilter, "field")).map( ([key, options]) => {
          return (
            <FilterGroup
              key={key}
              options={sortFilterOptions(options)}
              activeFilter={activeFilter}
              toggleFilter={toggleFilter}
            />)})}
        </div>
        <div id={"filter-footer"}
             style={{marginBottom: ((activeFilter.size >= 1) || (searchString !== "")) ? "0" : "-300px"}}
             className={ "filter-footer " + ((activeFilter.size >= 1) || (searchString !== "")) ? "is-open" : ""}>
          <div className="reset-background">
            <span className="button"
                  onClick={() => { resetFilter(); }}
                  style={{width: "auto"}}>
              <Trans>Reset Filters</Trans>
            </span>
          </div>

        </div>
      </div>)
}

export {deriveFilterQuery, mergeFilterSets, FilterWidget}
