import React, { useState, useEffect, useCallback } from "react"

import './SelfAssesment.css';

import { NavLink, useRouteMatch, useHistory } from 'react-router-dom';
import { FilterWidget } from "../Filter/Filter";
import { BackArrowSVG, ComplicanceViolationLabelSVG } from "../SVG/common";
import { RiskLabel } from "../Common/RiskLabel";
import { ResponseLabel } from "../Common/ResponseLabel"
import ComplianceLabel from "../Common/ComplianceLabel";
import { Subscription } from "react-apollo";


const SelfAssesmentDetail = () => {
  let match = useRouteMatch();
  const history = useHistory();
  // an ordered list (array) of all filters a user can select
  const [availableFilter, setAvailableFilter] = useState([]);

  // The filters that are currently active. The value in the set is the ident
  // of the filter in the availableFilter list
  const [activeFilter, setActiveFilter] = useState(new Set());

  // Compose an update function for the filters
  const updateActiveFilter = useCallback((filters) => setActiveFilter(filters),
    [availableFilter]);

  const assesmentID = match.params.assesment_id
  const tenantId = match.params.id;
  return (
    <div className="row">
      <div className="col-1-5">
        <FilterWidget
          availableFilter={availableFilter}
          activeFilter={activeFilter}
          updateActiveFilter={updateActiveFilter}
        />
      </div>
      <div className="col-sm-10">
      <div className="backlink"
                onClick={() => history.goBack()}>
              <div className="back-button">
                <BackArrowSVG />
                <span style={{marginLeft: "4px"}}>Back</span>
              </div>
            </div>
        <div className="device-details-title-bar">
          <h4 className="assesment-heading">ISO 27001: A.6.2.1 Richtlinie zu Mobilgeräten</h4>
        </div>
        
        <div className="background-table self-assesment-detail">
          <h5 className="heading-table" >Measure</h5>
          <p>Eine Richtlinie und unterstützende Sicherheitsmaßnahmen sind umgesetzt, um die Risiken, welche durch die Nutzung von Mobilgeräten bedingt sind, zu handhaben. </p>
        </div>

        <div>
          <div className="background-table self-assesment-detail">
            <table>
              <thead>
                <tr>
                  <th>Rules</th>
                  <th>Risk</th>
                  <th>Compliance</th>
                  <th>Response</th>
                  <th>Subscriber</th>
                  <th>Ticket</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>dac_modification_chmod</td>
                  <td><RiskLabel risk="HIGH"/></td>
                  <td><ComplianceLabel status="PASSED"/></td>
                  <td><ResponseLabel /></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>dac_modification_chmod</td>
                  <td><RiskLabel risk="HIGH"/></td>
                  <td><ComplianceLabel status="PASSED"/></td>
                  <td><ResponseLabel /></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>dac_modification_chmod</td>
                  <td><RiskLabel risk="HIGH"/></td>
                  <td><ComplianceLabel status="PASSED"/></td>
                  <td><ResponseLabel /></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>dac_modification_chmod</td>
                  <td><RiskLabel risk="HIGH"/></td>
                  <td><ComplianceLabel status="PASSED"/></td>
                  <td><ResponseLabel /></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>dac_modification_chmod</td>
                  <td><RiskLabel risk="HIGH"/></td>
                  <td><ComplianceLabel status="PASSED"/></td>
                  <td><ResponseLabel /></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>dac_modification_chmod</td>
                  <td><RiskLabel risk="HIGH"/></td>
                  <td><ComplianceLabel status="PASSED"/></td>
                  <td><ResponseLabel /></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>dac_modification_chmod</td>
                  <td><RiskLabel risk="HIGH"/></td>
                  <td><ComplianceLabel status="PASSED"/></td>
                  <td><ResponseLabel /></td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>


      </div>
    </div>
  )
}


export default SelfAssesmentDetail;
