/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback, useEffect } from 'react'
import { useQuery } from 'react-apollo'
import gql from 'graphql-tag'
import { useRouteMatch, useLocation } from 'react-router';
import Loader from '../Common/Loader';
//import { Trans } from 'react-i18next';
import DevicesTable from "./DevicesTable"
import Pagination from "../Pagination/Pagination"
import { FilterWidget, deriveFilterQuery, mergeFilterSets } from '../Filter/Filter';



export const GET_ASSETS = gql`
query DeviceListQuery($tenantId: String!, $skipItems: Int, $filters: [GenericFilterInput]){
  devices(
    tenantId: $tenantId,
    skip: $skipItems,
    filters: $filters,
    limit: 10,)
   {
    totalCount
    count
    filterOptions {
      field
      values {
      	name
      	count
      }
    }
    edges {
      cursor
      node {
        id
        displayName
        hostname
        operatingSystemName
        businessDomainNames
        risk
        lastScannedAt
      }
    }
  }
}
`

/**
The risk labels / filter available as default.
*/
// eslint-disable-next-line no-unused-vars
const defaultRiskFilter = [
  { category: "Risk", field: "RISK", value: "CRITICAL", ident: "risk_critical" },
  { category: "Risk", field: "RISK", value: "HIGH", ident: "risk_high" },
  { category: "Risk", field: "RISK", value: "MEDIUM", ident: "risk_medium" },
  { category: "Risk", field: "RISK", value: "LOW", ident: "risk_low" },
  { category: "Risk", field: "RISK", value: "INFO", ident: "risk_info" },
]

/**
Dynamically build an array of available filters from the data fetched from the backend.
*/
const filterFromData = (data) => {
  //console.log("Available Filter: ", data)
  let filter =
    data.map((category) => {
      return category.values.map((option) => {
        return {
          category: category.field,
          field: category.field,
          value: option.name,
          count: option.count,
          ident: (category.field + "_" + option.name).toLowerCase().replaceAll(' ', '_')
        }
      })
    })
  return [].concat.apply([], filter)
}

// Custom Hook that build on useLocation to parse the query String
function useQueryParam() {
  return new URLSearchParams(useLocation().search)
}

const Devices = () => {
  const match = useRouteMatch();
  const tenantId = match.params.id
  const [skipItems, setSkipItems] = useState(0);

  // Get query params from the URL
  let query = useQueryParam()

  // an ordered list (array) of all filters a user could set. These are all possible filters
  const [allAvailableFilter, setAllAvailableFilter] = useState([]);
  // an ordered list (array) of all filters a user can select. There are all filters that can be
  // set on the current query result. Subset of allAvailableFilter
  const [availableFilter, setAvailableFilter] = useState([]);

  // The filters that are currently active. The value in the set is the ident
  // of the filter in the availableFilter list
  const [activeFilter, setActiveFilter] = useState(new Set((query.get("filter") || " ").split(",")));
  activeFilter.delete(" ")
  // Compose an update function for the filters
  const updateActiveFilter = useCallback((filters) => setActiveFilter(filters),
    [availableFilter]);

  let [searchString, setSearchString] = useState("");
  let triggerSearch = (searchString) => setSearchString(searchString);

  const { loading, error, data } = useQuery(GET_ASSETS, {
    variables:
    {
      tenantId: tenantId,
      skipItems: skipItems,
      filters: deriveFilterQuery(availableFilter, activeFilter, searchString),
    },
  });

  // The available filters are derived from the data received from the backend.
  // We have to wait until the devices list was received from the server. Only then we are capable
  // of creating the filers.
  useEffect(() => {
    if ((data !== undefined) && (allAvailableFilter.length === 0)) {
      setAllAvailableFilter(filterFromData(data.devices.filterOptions))
    }
    if (data !== undefined) {
      setAvailableFilter(mergeFilterSets(allAvailableFilter, filterFromData(data.devices.filterOptions)))
    }
  },
    [data]);


  if (loading && (data === undefined)) {
    return <Loader />;
  }
  if (error) {
    return <p>Error!!</p>;
  }

  let { edges, totalCount } = data.devices;
  const amountPages = Math.ceil(totalCount / 10) * 10 / 10
  let devicesList = []
  edges.map(e => devicesList.push(e.node))

  if(devicesList.length === 0 && searchString === ""){
    return (
    <div className="col-sm-12">
      <div className="background-table insights">
        <p className="no-devices">No Devices added</p>
      </div>
    </div>
    )
  }

  return (
    <div className="findings">
      <div className="row">
        {<div className="col-1-5">
          <FilterWidget
            availableFilter={availableFilter}
            activeFilter={activeFilter}
            updateActiveFilter={updateActiveFilter}
            triggerSearch={triggerSearch}
            setSkipItems={setSkipItems}
          />
        </div>}
        <div className={"col-sm-10"}>
          <div className="background-table insights">
            <DevicesTable devices={devicesList} activeFilter={activeFilter}/>
          </div>

          {amountPages <= 1 ? null : <Pagination
            amountPages={amountPages}
            setSkipItems={setSkipItems}
            skipItems={skipItems} />}
        </div>
      </div>
    </div>)
}


export default Devices
