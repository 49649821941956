import React from 'react';
import { NavLink, useRouteMatch } from "react-router-dom"
import "./Menu.css"


const MenuOption = ({ svg, name }) => {
  let match = useRouteMatch();
  let tenantId = match.params.id
  return (
    <div className={`nav-item `} >
      {/* <div className="caption"> */}
        <NavLink activeClassName={"active"} className={"link"} to={`/${tenantId}/${name.toLowerCase().replace(" ", "_")}`}>
        <div className="svg">{svg}</div>
          {name}
        </NavLink>
      </div>

    // </div>
  );
}

export default MenuOption;
