import React from 'react';
import { NextArrowBlueSVG } from "../SVG/common"
// import { VulnerabilityLabelSVG } from "../SVG/common"

const CardStats = ({ heading, amount, svg, total}) => {

  return (

      < div className="card" style={{minWidth: "328px"}}>
        <div className="card-body pt-4 pl-4">
          <div style={{ display: "flex", alignItems: "center", marginBottom: "1rem"}}>
            {svg}
            {heading === "Scan Coverage" ? <><p style={{
              fontStyle: "normal",
              fontWeight: "600",
              fontSize: "20px",
              lineHeight: "30px",
              marginLeft: "0.5rem",
              marginRight: "0.5rem",
              marginBottom: "0rem",
              color: "#0D276B",
            }}>{heading} <span className="scan">24 hours</span></p></> : <p style={{
              fontStyle: "normal",
              fontWeight: "600",
              fontSize: "20px",
              lineHeight: "30px",
              marginLeft: "0.5rem",
              marginRight: "0.5rem",
              marginBottom: "0rem",
              color: "#0D276B"}}>{heading}</p> }
            {heading === "Scan Coverage" ? "" :<NextArrowBlueSVG />}
          </div>
            {heading === "Affected Devices"
            ?
            <p className="body-heading">{amount}<span className="grey-card-text">/{total}</span></p>
            :
            heading === "Scan Coverage"
            ?
            <p className="body-heading">{amount}<span className="grey-card-text">%</span></p>
            :
            <p className="body-heading">{amount}</p>
          }


        </div >
      </div >


  );
}

export default CardStats;
