import React from "react"
import { Query } from "react-apollo"
import gql from "graphql-tag"
//import { Trans } from 'react-i18next'
import { NavLink } from "react-router-dom";
import Loader from "../Common/Loader"
// import InsightsTable from "../Insights/InsightsTable"


export const GET_TENANTS = gql`
query {
  tenants {
    tenantId
    displayName
    name
    homepage
    vulnerabilityFindingsCount
    complianceViolationsCount
    devicesCount
    subscription
  }
}
`


const TennantsTable = ({tenants}) => {
  return (
     <table className="table-in-card ">
       <thead>
         <tr>
           <th className="alignment">Name</th>
           <th className="alignment">Devices</th>
           <th className="alignment">Vulnerabilities</th>
           <th className="alignment">Compliance Violations</th>
         </tr>
       </thead>
       <tbody>
         {tenants.map((ele, i) => {
           // to={`/${tenant.tenantId}/dashboard`}
           // onClick={() => {
           //   localStorage.setItem("tenantName", tenant.displayName)
           // }}
           return (
             <tr key={i} onClick={() => { localStorage.setItem("tenantName", ele.displayName) }}>
               <td><NavLink to={`/${ele.tenantId}/dashboard`}><span style={{ marginLeft: "0.5rem" }}>{ele.displayName}</span></NavLink></td>
               <td><NavLink to={`/${ele.tenantId}/dashboard`}>{ele.devicesCount}</NavLink></td>
               <td><NavLink to={`/${ele.tenantId}/dashboard`}>{ele.vulnerabilityFindingsCount}</NavLink></td>
               <td><NavLink to={`/${ele.tenantId}/dashboard`}>{ele.complianceViolationsCount}</NavLink></td>
             </tr>
           )
         })}
       </tbody>
     </table>)
}


export default function Tenants() {
  if (localStorage.getItem("tenantName")) {
    localStorage.removeItem("tenantName")
  }

  return (
    <Query query={GET_TENANTS}>

      {({ loading, error, data }) => {
        if (loading) return <Loader />
        if (error) return <div>Error</div>

        const tenants = data.tenants

        return (

          <div className="center-card">
            <div className="background-table insights width-tenants">
              <TennantsTable tenants={tenants} />
            </div>
          </div>

        )
      }}
    </Query>

  )
}
