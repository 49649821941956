import React from 'react';
import {Dropdown} from "react-bootstrap"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AUTH_TOKEN } from '../../../constants';
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons'
import { Trans } from 'react-i18next';
import { NavLink } from 'react-router-dom'
import styled from "styled-components"
import { SettingsIcon, DarkModeIcon, LogoutIcon, TenantsIcon } from '../../SVG/common';

const Avatar = styled.p`

border-radius: 50%;
width: 36px;
height: 36px;
align-items: center;
padding-top: 4px;

background: #FFFFFF;
border 2px solid #fff;
color: #0F57A6;
text-align: center;
margin-bottom: 0;
margin-left: 21px;
margin-right: 8px;
font-size: 16px;
`




const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  // eslint-disable-next-line jsx-a11y/anchor-is-valid
  <a
    href=""
    className="button-style"
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
    <FontAwesomeIcon icon={faCaretDown} className="width" />
  </a>
));

const onLogout = () => {
  localStorage.removeItem(AUTH_TOKEN);
  window.location.reload()
}
const Profile = ({sub}) => {
  const avatarLetters = sub.first_name.slice(0,1) + sub.last_name.slice(0,1);
  return (<>
    <Avatar>{avatarLetters}</Avatar>
    <div>
      <Dropdown id="dropdown-menu-align-right">
             <Dropdown.Toggle as={CustomToggle} >
               {localStorage.getItem("tenantName") ? localStorage.getItem("tenantName") : sub.sub}
             </Dropdown.Toggle>
             <Dropdown.Menu >
               <NavLink to={`/tenants`} className='dropdown-item'><TenantsIcon /><Trans>Tenants</Trans></NavLink>
               <Dropdown.Divider />
               {/*<Dropdown.Item ><SettingsIcon /><Trans>Settings</Trans></Dropdown.Item>*/}
               {/*<Dropdown.Divider />*/}
               <Dropdown.Item onClick={onLogout}><LogoutIcon />Logout</Dropdown.Item>
             </Dropdown.Menu>
           </Dropdown>
    </div>
    </>
  );
}

export default Profile;
