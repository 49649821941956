/* eslint-disable no-unused-vars */
import React, {useState} from 'react';
import {Trans, useTranslation} from "react-i18next";
import gql from 'graphql-tag'
import {useMutation} from 'react-apollo'
import {AUTH_TOKEN} from "../../constants";
import {useHistory, useLocation} from "react-router";
import logo from '../../assets/img/new_CISOSCOPE_logo_complete_blue.svg'
import './Login.css'

// const REGISTER_MUTATION = gql`
//   mutation RegisterMutation($email: String!, $password: String!, $firstname: String, $lastname: String) {
//     register(email: $email, password: $password, firstName: $firstname, lastName: $lastname) {
//       token
//       tokenType
//     }
//   }
// `

const LOGIN_MUTATION = gql`
  mutation LoginMutation($email: String!, $password: String!) {
    login(username: $email, password: $password) {
      token
      tokenType
    }
  }
`

const LoginError = ({errorType}) => {
  let errorMessage = "";
  switch(errorType) {
    //
    case "invalidCredentials":
      errorMessage = "Your username or password is invalid. Please try again.";
      break;
    //
    case "serverUnavailable":
      errorMessage = "It's not possible to login at this time. Please try again later.";
      break;
    //
    case "unknownError":
      errorMessage = "It's not possible to login at this time. Please try again later.";
      break;
    //
    default:
      errorMessage = "";
      break;
  }

  return (
    <div className="login-error">
      <h3><Trans>Login Failed</Trans></h3>
      <span className="error-message"><Trans>{errorMessage}</Trans></span>
    </div>);
}

const Login = () => {
    const {t} = useTranslation();

    const [login, setLogin] = useState(true);
    const [error, setError] = useState(null);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const history = useHistory();
    let location = useLocation();

    let {pathname} = location.from || {pathname: "/tenants"};


    const [mutation, {mutationLoading, mutationError}] = useMutation(LOGIN_MUTATION, {
            onError: ({graphQLErrors, networkError}) => {
                if(graphQLErrors) {
                  setError("invalidCredentials");
                } else if(networkError) {
                  setError("serverUnavailable");
                } else {
                  setError("unknownError");
                }
            },
            onCompleted: (data) => {
                const {token} = login ? data.login : data.register
                localStorage.setItem(AUTH_TOKEN, token)
                history.push(pathname)
            }
        }
    );


    return (
        <div className="login-view-container">
            <div className="row justify-content-center">
                <div className="col-md-4 col-md-offset-4" style={{height: "100%", margin: "15rem"}}>
                    <div className="login-panel panel panel-default">
                        <div className="panel-heading text-center">
                          <img src={logo} alt="CISOSCOPE Logo" style={{width: '285px', marginBottom: '30px'}}/>
                        </div>
                        <div className="panel-body">
                            <form className="login"
                                  onSubmit={e => {
                                    e.preventDefault();
                                    mutation({variables: {email, password}});
                            }}>
                              <fieldset>
                                <input
                                  type="text"
                                  id="user-email"
                                  value={email}
                                  onChange={e => setEmail(e.target.value)}
                                  placeholder={t("E-Mail Address")}
                                />
                                <input
                                  type="password"
                                  id="user-password"
                                  value={password}
                                  onChange={e => setPassword(e.target.value)}
                                  placeholder={t("Password")}
                                />
                                <button className="login"
                                        type="submit">
                                  {t('Login')}
                                </button>
                              </fieldset>
                            </form>
                            {mutationLoading && <p>Loading...</p>}
                            {(error !== null) && <LoginError errorType={error}/>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;
