import React from 'react';
import MenuOption from "./MenuOption"
const Menu = () => {
  return (
    <ul className="nav m-auto">
      <MenuOption
        svg={<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="0.85" y="0.85" width="22.3" height="22.3" rx="5.15" stroke="white" strokeWidth="1.7" />
          <path d="M7 15L10.1429 10.3333L13.4286 13.6667L17 9" stroke="white" strokeWidth="1.7" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
        }
        name="Dashboard" />
      <MenuOption svg={<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="-0.85" y="-0.85" width="22.3" height="22.3" rx="5.15" transform="matrix(-4.37114e-08 -1 -1 4.37114e-08 22.3 22.3)" stroke="white" strokeWidth="1.7" />
        <line x1="0.85" y1="-0.85" x2="8.15" y2="-0.85" transform="matrix(-1 4.37114e-08 4.37114e-08 1 15 17.5)" stroke="white" strokeWidth="1.7" strokeLinecap="round" />
        <line x1="0.85" y1="-0.85" x2="11.15" y2="-0.85" transform="matrix(-1 4.37114e-08 4.37114e-08 1 18 12.5)" stroke="white" strokeWidth="1.7" strokeLinecap="round" />
        <line x1="0.85" y1="-0.85" x2="5.15" y2="-0.85" transform="matrix(-1 4.37114e-08 4.37114e-08 1 12 7.5)" stroke="white" strokeWidth="1.7" strokeLinecap="round" />
      </svg>
      }
        name="Insights" />
      <MenuOption svg={<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3 14V5.09524C3 4.53955 3.21071 4.00661 3.58579 3.61368C3.96086 3.22075 4.46957 3 5 3H19C19.5304 3 20.0391 3.22075 20.4142 3.61368C20.7893 4.00661 21 4.53955 21 5.09524V14" stroke="white" strokeWidth="1.7" strokeMiterlimit="10" strokeLinecap="square" />
        <path d="M15.5 16V17.5H8.5V16H1V17.5C1 18.4283 1.36875 19.3185 2.02513 19.9749C2.6815 20.6313 3.57174 21 4.5 21H19.5C20.4283 21 21.3185 20.6313 21.9749 19.9749C22.6313 19.3185 23 18.4283 23 17.5V16H15.5Z" stroke="white" strokeWidth="1.7" strokeMiterlimit="10" strokeLinecap="square" />
      </svg>
      }
        name="Devices" />
      <MenuOption svg={<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M17 2H19C20.6569 2 22 3.34315 22 5V20C22 21.6569 20.6569 23 19 23H5C3.34315 23 2 21.6569 2 20V5C2 3.34315 3.34315 2 5 2H7" stroke="white" strokeWidth="1.7" strokeMiterlimit="10" strokeLinecap="square" />
        <path d="M9 15L11 17L15 13" stroke="white" strokeWidth="1.7" strokeMiterlimit="10" strokeLinecap="square" />
        <path d="M7 1V7H10C10 5.895 10.895 5 12 5C13.105 5 14 5.895 14 7H17V1H7Z" stroke="white" strokeWidth="1.7" strokeMiterlimit="10" strokeLinecap="square" />
      </svg>
      }
        name="Reports" />
      {/* <MenuOption svg={<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M18.5 15.5C16.8431 15.5 15.5 16.8431 15.5 18.5C15.5 20.1569 16.8431 21.5 18.5 21.5C20.1569 21.5 21.5 20.1569 21.5 18.5C21.5 16.8431 20.1569 15.5 18.5 15.5Z" stroke="white" strokeWidth="1.7" strokeMiterlimit="10" />
        <path d="M5.5 15.5C3.84315 15.5 2.5 16.8431 2.5 18.5C2.5 20.1569 3.84315 21.5 5.5 21.5C7.15685 21.5 8.5 20.1569 8.5 18.5C8.5 16.8431 7.15685 15.5 5.5 15.5Z" stroke="white" strokeWidth="1.7" strokeMiterlimit="10" />
        <path d="M12 3C10.3431 3 9 4.34315 9 6C9 7.65685 10.3431 9 12 9C13.6569 9 15 7.65685 15 6C15 4.34315 13.6569 3 12 3Z" stroke="white" strokeWidth="1.7" strokeMiterlimit="10" />
        <path d="M12 9L12 13L8 16" stroke="white" strokeWidth="1.7" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M12 13L16 16" stroke="white" strokeWidth="1.7" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
      } name="Knowledge Graph" /> */}
      <MenuOption svg={<svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.85" y="-0.85" width="22.3" height="22.3" rx="5.15" transform="matrix(1 0 0 -1 0.5 22.3)" stroke="white" strokeWidth="1.7" />
        <line x1="0.85" y1="-0.85" x2="6.15" y2="-0.85" transform="matrix(1 0 -2.24004e-08 -1 12.5 15)" stroke="white" strokeWidth="1.7" strokeLinecap="round" />
        <line x1="0.85" y1="-0.85" x2="6.15" y2="-0.85" transform="matrix(1 0 -2.24004e-08 -1 12.5 8)" stroke="white" strokeWidth="1.7" strokeLinecap="round" />
        <path d="M5.5 8.28571L6.95455 10L9.5 7" stroke="white" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M5.5 15.2857L6.95455 17L9.5 14" stroke="white" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
      } name={"Self Assesment"} />
    </ul>
  );
}

export default Menu;
