/* eslint-disable array-callback-return */
import React from 'react'
import {Switch, Route, Redirect, Router} from 'react-router-dom'

import 'bootstrap/dist/css/bootstrap.min.css'

import './assets/css/sb-admin-2.min.css'
import './App.css'
//import views
import Dashboard from "./components/Dashboard/Dashboard"
import PageNotFound from "./components/PageNotFound"
import Login from "./components/Login/Login"

import Devices from "./components/Devices/Devices"
import Tasks from "./components/Tasks/Tasks"
import Settings from "./components/Settings/Settings"
import KnowledgeGraph from "./components/KnowledgeGraph/KnowledgeGraph";
import PrivateRoute from "./utils/PrivateRoute";
import DeviceDetails from "./components/Devices/DeviceDetails/DeviceDetails";
import Tenants from './components/Tenants/Tenants'

import {StateProvider} from "./components/StateContext"
import ReportBusiness from './components/ReportBusiness/ReportBusiness'
import Insights from "./components/Insights/Insights";
import InsightsDetail from "./components/Insights/InsightsDetail/InsightsDetail"
import Support from "./components/Support/Support";
import {ApolloProvider} from "react-apollo";
import {createHttpLink} from "apollo-link-http";

import {setContext} from "apollo-link-context";
import {onError} from "apollo-link-error";
import {ApolloClient} from "apollo-client";
import {ApolloLink} from "apollo-link";
import {InMemoryCache} from "apollo-cache-inmemory";


import './i18n'
import './index.css'
import {AUTH_TOKEN, BACKEND_GRAPHQL_URI, HOTJAR_ID, HOTJAR_SNIPPET_VERSION} from "./constants";
import jwt_decode from "jwt-decode";
import history from './history'
import ArchitectureDomainsReport from "./components/Reports/ArchitectureDomainsReport";
import Vulnerabilities from "./components/ReportBusiness/Vulnerabilities"
import SelfAssesment from "./components/SelfAssesment/SelfAssesment"
import SelfAssesmentDetail from "./components/SelfAssesment/SelfAssesmentDetail"
import DeactivatedFeature from "./components/Common/DeactivatedFeature"

import { hotjar } from 'react-hotjar';


hotjar.initialize(HOTJAR_ID, HOTJAR_SNIPPET_VERSION);



const App = () => {

    const httpLink = createHttpLink({
        uri: BACKEND_GRAPHQL_URI,
    })

    const deprecatedLink = createHttpLink({
      uri: "https://api.cisoscope.com/api/v1/graphql"
    });

    const authLink = setContext((_, {headers}) => {
        const token = localStorage.getItem(AUTH_TOKEN)

        if (token === null) {
            return {
                headers: {
                    ...headers,
                }
            }
        }
        const { exp } = jwt_decode(token)
        if (Date.now() > exp * 1000) {
            localStorage.clear();
            history.push("/");
            return {
                headers: {
                    ...headers,
                }
            }
        }
        return {
            headers: {
                ...headers,
                authorization: token ? `Bearer ${token}` : '',
            },
        }
    })

    const errorLink = onError(({graphQLErrors}) => {
        if (graphQLErrors) graphQLErrors.map(({message}) => {
            console.log("GraphQL Error: " + message)
        })
    })


    const client = new ApolloClient({
        // link: ApolloLink.from([authLink, errorLink, httpLink]),
        cache: new InMemoryCache(),
        link: ApolloLink.split(
          operation => operation.getContext().clientName === "deprecated",
          ApolloLink.from([authLink, errorLink, deprecatedLink]),
          ApolloLink.from([authLink, errorLink, httpLink]),

        )
    })

    const initialState = {
        tenantId: "",
        sidebarOpen: true

    }

    const reducer = (state, action) => {
        switch (action.type) {
            case "setId":
                return {
                    ...state,
                    tenantId: action.newId
                };
            case "toggleSidebar":
                return {
                    ...state,
                    sidebarOpen: action.setToggle,
                }
            case "getSideBarState":
                return state.sidebarOpen
            default:
                return state;
        }
    }


    return (
        <Router history={history}>
            <ApolloProvider client={client}>
                <StateProvider initialState={initialState} reducer={reducer}>
                    <Switch>
                        <Route exact path="/login" component={Login}/>
                        <PrivateRoute exact path="/tenants" layout="LayoutWithoutSidebar" component={Tenants}/>
                        <PrivateRoute exact path="/:id/dashboard" layout="LayoutWithSidebar" component={Dashboard}/>

                        {/*Reports*/}

                        <PrivateRoute path="/:id/reports/architecture-domains" layout="LayoutWithSidebar" component={ArchitectureDomainsReport}/>
                        <PrivateRoute path="/:id/reports/vulnerabilities" layout="LayoutWithSidebar" component={Vulnerabilities}/>


                        <PrivateRoute path="/:id/reports" layout="LayoutWithSidebar" component={ReportBusiness}/>
                        <PrivateRoute path="/:id/devices/:device_id" layout="LayoutWithSidebar"
                                      component={DeviceDetails}/>
                        <PrivateRoute exact path="/:id/devices" layout="LayoutWithSidebar" component={Devices}/>
                        <PrivateRoute exact path="/:id/tasks" layout="LayoutWithSidebar" component={Tasks}/>
                        <PrivateRoute exact path="/:id/insights/:insight_id" layout="LayoutWithSidebar" component={InsightsDetail} />
                        <PrivateRoute exact path="/:id/insights" layout="LayoutWithSidebar" component={Insights}/>
                        <PrivateRoute exact path="/:id/knowledge graph" layout="LayoutWithSidebar"
                                      component={KnowledgeGraph}/>
                        <PrivateRoute exact path="/:id/self_assesment" layout="LayoutWithSidebar" component={DeactivatedFeature} />
                        <PrivateRoute exact path="/:id/self_assesment/:assesment_id" layout="LayoutWithSidebar" component={SelfAssesmentDetail} />
                        <PrivateRoute exact path="/:id/settings" layout="LayoutWithSidebar" component={Settings}/>
                        <PrivateRoute exact path="/:id/support" layout="LayoutWithSidebar" component={Support}/>

                        <Route path="/404" component={PageNotFound}/>
                        <PrivateRoute exact path="/">
                            <Redirect to="/tenants"/>
                        </PrivateRoute>

                        <Redirect to="/404"/>

                    </Switch>


                </StateProvider>
            </ApolloProvider>
        </Router>
    )

}

export default App
