/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
import React, { useState, useCallback } from "react";

import './InsightsDetail.css';

import {NavLink, useRouteMatch } from 'react-router-dom';
import {useHistory} from "react-router";
import {useQuery} from 'react-apollo';
import gql from 'graphql-tag';
import {Trans} from 'react-i18next';

import Loader from "../../Common/Loader";
import { VulnerabilityLabelSVG, ComplicanceViolationLabelSVG, ThreatLabelSVG, IncidentLabelSVG,
         DropdownArrowSVG, BackArrowSVG, NextArrowSVG, SeperatorSVG } from "../../SVG/common";
import { FilterWidget, deriveFilterQuery } from "../../Filter/Filter";
import { getMaxRisk, RiskLabel } from "../../Common/RiskLabel";
import { TableTextField, TableLimitedTextField, TableTextFieldWithExpand, SubscriberField,
         TableTextFieldWithLink, TicketField, ResponseField } from "../../DynamicTable/Fields";
import { TableGroupRow, PaginationWidget } from "../../DynamicTable/DynamicTable";
import { stringHash } from "../../../utils/common";
import convertDateToUtc_Short from "../../Common/helpers";
import { groupByFn, select } from '../../../utils/Functionals';


const GET_COMPLIANCE_DETAIL = gql`
query{complianceInsight(tenantId:"98111",id:"A.9.2.1",
      filters:[
      {field: RISK, values:["MEDIUM"]},
       {field:COMPLIANCE, values:["pass"]}]				
){
  isoSubChapterGroup,
  isoSubChapter
  	{
      name,
    	policyGroupId,
      createdAt,
      ruleUri,
      updatedAt,
      description{
        isoId, 
        chapter,
        description
      }
    },
  findings{
    findingId,
    device,
    createdAt,
    result,
    scannedAt,
    severity,
  },
  isoChapter{
    name,
    policyGroupId,
    createdAt,
    updatedAt,
    description{
    	isoId,
      chapter,
      description
    }
  },
  isoSection{
    name,
    policyGroupId,
    createdAt,
    updatedAt,
    description{
    	isoId,
      chapter,
      description
    }
  },
  amountRules,
  displayText
}}
`

const GET_INSIGHT_DETAIL = gql`
query InsightDetail($tenantId: String!, $insightId: String!){
  insight(tenantId: $tenantId, id: $insightId) {
    id
    displayName
    insightType
    updatedAt
    firstSeenAt
    risk

    description
    ... on VulnerabilityInsight {
      attackVector
      weaknesses
      consequences
      businessDomainsCount
      devicesCount
      findings {
        findingId
        id
        businessDomain {
          id
          displayName
        }
        device {
          id
          displayName
        }
        platform {
          id
          name
          version
        }
        risk
        response
        done
        subscribers {
          __typename
          id
          email
          firstName
          lastName
        }
        ticketId
      }
    }
  }
}
`

const GET_FINDINGS_UPDATE = gql`
query InsighFindings($tenantId: String!, $findingId: String!) {
  finding(tenantId: $tenantId, id: $findingId) {
    id
    findingId
    businessDomain {
      displayName
    }
    device {
      id
      displayName
    }
    platform {
      name
      version
    }
    risk
    response
    done
    subscribers {
      __typename
      id
      email
      firstName
      lastName
    }
    ticketId
  }
}
`

/**
* Render single Card with details of the vulnerability vector.
*/
const VulnerabilityVectorCard = ({title, type, listItems}) => {
  return (
    <div className="card vector-card">
      <h4><Trans>{title}</Trans></h4>
      <ul>
        {listItems.map((t) => <li key={stringHash(type+t)}>{t}</li>)}
      </ul>
    </div>
  )
}

/**
* Render a Card with the summary of the Insight
*/
const SummaryCard = ({description, name, type, lastUpdate, risk}) => {
  return (
    <div className="card summary-card">
      <table className="table-in-card details ">
        <thead>
          <tr>
            <th className="alignment"><Trans>Insight</Trans></th>
            <th className="alignment"><Trans>Type</Trans></th>
            <th className="alignment"><Trans>First Seen At</Trans></th>
            <th className="alignment"><Trans>Risk</Trans></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{name}</td>
            <td><Trans>{type}</Trans></td>
            <td>{convertDateToUtc_Short(lastUpdate)}</td>
            <td><RiskLabel risk={risk}/></td>
          </tr>
        </tbody>
      </table>
      <div className="summary">
        <h4><Trans>Insight Description (Source CVE)</Trans></h4>
        <p>{description}</p>
      </div>
    </div>)
}


/**
* Select and render a table field widget. Returns the correct component for the field depending
* on the table field declaration.
*/
const renderWidget = (widget, value, props = {}) => {
  switch(widget) {
    case "TableTextField":
      return (<TableTextField value={value} props={props}/>);
    case "TableLimitedTextField":
      return (<TableLimitedTextField value={value} props={props}/>);
    case "TableLinkField":
      return (<TableTextFieldWithLink value={value} location={`/${props._tenantId}/devices/${props._deviceId}`}/>);
    case "TextFieldWithExpand":
      return (<TableTextFieldWithExpand
                value={value}
                expanded={props.expanded}
                toggleExpanded={props.toggleExpanded}
              />);
    case "TicketField":
      return (<TicketField
                ticketId={value}
                postRemove={() => console.log("Remove Ticket - Post callback called!")}
                postAdd={() => {}}
                tenantId={props._tenantId}
                findingId={props._insightId}
                disabled={props._disabled}
              />);
    case "SubscriberField":
      return (<SubscriberField
                subscribers={value}
                postRemove={() => console.log("Remove Subscriber - Post callback called!")}
                postAdd={() => {}}
                tenantId={props._tenantId}
                findingId={props._insightId}
                disabled={props._disabled}
              />);
    case "RiskLabel":
      return (<RiskLabel risk={value} />);
    case "ResponseField":
      return (<ResponseField
                response={value}
                done={props._done}
                postUpdate={() => console.log("Response Update - Post callback called!")}
                postDone={() => console.log("Response Done - Post callback called!")}
                tenantId={props._tenantId}
                findingId={props._insightId}
                disabled={props._disabled}
              />)
    case "Empty":
      return <br/>
    default:
      return (<TableTextField value={value} props={props}/>);
  }
}

const getWidgetFromName = (fieldName, fields) => {
  return fields.find((fd) => { if(fd.name === fieldName) { return fd.component }});
}




/**
* Transform the data from the backend to a collection that can be understood by the dynamic table.
*/
const findingsToTableData = (findings) => {
  let groupedFindings = groupByFn(findings, (e) => [e.businessDomain.displayName, e.platform.name]);
  return Object.entries(groupedFindings).map( ([key, items]) => {
    let [domain, platform] = key.split(",")
    return {domain: domain,
            platform: platform,
            risk: getMaxRisk(select(items, (item) => item.risk)),
            response: select(items, (child) => child.response),
            done: items.reduce((acc, child) => (acc && child.done), true),
            subscribers: Array.from(new Set(select(items, (item) => item.subscribers).flat())),
            ticket: items.reduce((acc, child) => {
                                  if((child.ticketId !== null) && (child.ticketId !== undefined)) {
                                    return acc+1
                                  } else {
                                    return acc}}, 0),
            children: items.map((child) => {return {insightId: child.findingId,
                                                    id: child.id,
                                                    device: child.device.displayName,
                                                    deviceId: child.device.id,
                                                    software: child.platform.version,
                                                    risk: child.risk,
                                                    response: child.response,
                                                    done: child.done,
                                                    subscribers: child.subscribers,
                                                    ticket: child.ticketId}})
           }
  })
}

/**
* Widget to show that no entries for the details view are available
*/
const NoEntries = () => {
  return (
    <div className="no-entries">
      <Trans>There is no additional information available about this Insight.</Trans>
    </div>
  )
}


const DetailsSection = ({data, filterDone, setFilterDone}) => {
  let match = useRouteMatch();
  const tenantId = match.params.id;

  // Declare how the table groups are rendered using a dynamic table
  const tableGroupFields = [{name:      "domain",
                             component: "TextFieldWithExpand"},
                            {name:      "platform",
                             component: "TableLimitedTextField"},
                            {name:      "risk",
                             component: "RiskLabel"},
                            {name:      "response",
                             component: "ResponseField"},
                            {name:      "subscribers",
                             component: "SubscriberField"},
                            {name:      "ticket",
                             component: "TicketField"}];
  // Declare how the table data rows are rendered using a dynamic table
  const tableChildrenFields = [{name:      "device",
                                component: "TableLinkField"},
                               {name:      "software",
                                component: "TableLimitedTextField"},
                               {name:      "risk",
                                component: "RiskLabel"},
                               {name:      "response",
                                component: "ResponseField"},
                               {name:      "subscribers",
                                component: "SubscriberField"},
                               {name:      "ticket",
                                component: "TicketField"}];

  const selectWidget = (name, value, context, props = {}) => {
    if(name === "subscribers") {
      props._tenantId = tenantId;
      props._insightId = context.id;
      props._disabled = true;
    }
    if(name === "ticket") {
      props._tenantId = tenantId;
      props._insightId = context.id;
      props._disabled = true;
    }
    if(name === "response") {
      props._tenantId = tenantId;
      props._insightId = context.id;
      props._disabled = true;
      props._done = context.done;
    }
    return renderWidget(getWidgetFromName(name, tableGroupFields).component, value, props)
  };

  const selectChildrenWidget = (name, value, context, props = {}) => {
    if(name === "device") {
      props._deviceId = context.deviceId;
      props._tenantId = tenantId;
    }
    if(name === "subscribers") {
      props._tenantId = tenantId;
      props._insightId = context.id;
      props._disabled = false;
    }
    if(name === "ticket") {
      props._tenantId = tenantId;
      props._insightId = context.id;
      props._disabled = false;
    }
    if(name === "response") {
      props._tenantId = tenantId;
      props._insightId = context.id;
      props._disabled = false;
      props._done = context.done;
    }
    return renderWidget(getWidgetFromName(name, tableChildrenFields).component, value, props)
  };

  let businessDomainsCount = new Set(data.map((d) => d.domain)).size;
  let devicesCount = new Set(select(Object.values(select(data, (o) => o.children)).flat(), (o) => o.device)).size;

  // paginagtion: default entries per page and state
  const entriesPerPage = 10;
  let [currentPage, setCurrentPage] = useState(1);

  return (
    <div className="details-section">
      <div className="details-filter-container">
        <ul>
          <li className={!filterDone ? "active" : ""}
              onClick={(e) => setFilterDone(false)}>
            <Trans>All Insights</Trans>
          </li>
          <li className={filterDone ? "active" : ""}
              onClick={(e) => setFilterDone(true)}>
            <Trans>Done</Trans>
          </li>
        </ul>
      </div>
      <div className="card details-table-card">
        {(data === null || data === undefined || data.length === 0)
         ?
          <NoEntries />
         :
          <table className="table-in-card ">
            <thead>
              <tr className="no-hover">
                <th className="alignment">
                  <Trans>Business Domains</Trans>
                  {" ("+businessDomainsCount+")"}
                  {" / "}
                  <Trans>Device</Trans>
                  {" ("+devicesCount+")"}
                </th>
                <th className="alignment software"><Trans>Software</Trans></th>
                <th className="alignment risk"><Trans>Risk</Trans></th>
                <th className="alignment response"><Trans>Response</Trans></th>
                <th className="alignment subscriber"><Trans>Subscriber</Trans></th>
                <th className="alignment ticket"><Trans>Ticket</Trans></th>
              </tr>
            </thead>
              {data.slice((currentPage-1)*entriesPerPage, currentPage*entriesPerPage).map((d) =>
                <TableGroupRow
                  key={d.platform+d.domain}
                  row={d}
                  fields={tableGroupFields}
                  childrenFields={tableChildrenFields}
                  widgetFn={selectWidget}
                  childrenWidgetFn={selectChildrenWidget}
                />
              )}
          </table>}
      </div>
      {(data === null || data === undefined || data.length <= 1)
        ? null
        : <PaginationWidget
            current={currentPage}
            setCurrent={setCurrentPage}
            totalPages={Math.ceil(data.length / entriesPerPage)}
          />}
    </div>
  )
}


export default function InsightsDetail () {
    let match = useRouteMatch();
    const history = useHistory();

    const insight_id = match.params.insight_id
    const tenantId = match.params.id;
    
    console.log(insight_id)
    // an ordered list (array) of all filters a user can select
    const [availableFilter, setAvailableFilter] = useState([]);

    // The filters that are currently active. The value in the set is the ident
    // of the filter in the availableFilter list
    const [activeFilter, setActiveFilter] = useState(new Set());

    // Compose an update function for the filters
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const updateActiveFilter = useCallback((filters) => { setActiveFilter(filters) },[availableFilter]);

    const [filterDone, setFilterDone] = useState(false);


    // Get the data from the backend
    // const {data: complianceInsight, error: complianceQueryError, loading: complianceQueryLoading} = useQuery(
    //   GET_COMPLIANCE_DETAIL
    // )
    const { loading, error, data } = useQuery(GET_INSIGHT_DETAIL, {
      variables: { tenantId: tenantId, insightId: insight_id }
    });
    
    // When no data has been returned by the backend yet, show the spinner.
    // Checking for the `undefined` will avaoid showing the spinner when a filter set was changed
    // but no new data has arrived yet. In this case we will just continue to show the old (unfiltered)
    // data. This is much nicer compared to showing a spinner every time a user changes a filter.
    if (loading && (data === undefined)) {
      return <Loader />;
    }
    // When there was an error fetching the data from the server, show an error.
    if(error) return <p>Error!!</p>;
    // get the matching component for the insight type
    const getInsightLabel = (type) => {
      switch(type.toUpperCase()) {
        case "VULNERABILITY": return <VulnerabilityLabelSVG value={32}/>
        case "COMPLIANCE": return <ComplicanceViolationLabelSVG value={32}/>
        case "THREAT": return <ThreatLabelSVG value={32}/>
        case "INCIDENT": return <IncidentLabelSVG value={32}/>
        default: return ""
      }
    }

    let insight = data.insight;
    // get the insights and apply filter for done attribute when required
    let insightTableData 
    if(insight){
     insightTableData = findingsToTableData(insight.findings.filter( finding => { if(filterDone) {return finding.done} else {return true}}));
    }



    return (
      <div className="insight-details">
          {insight === null ?
            <div className="row">
              <div className="col-sm-12">
                <p>ERROR. INSIGHT DOES NOT EXIST!</p>
              </div>
            </div>
            :
            <div className="row">
              <div className="col-sm-2">
                <FilterWidget
                  availableFilter={availableFilter}
                  activeFilter={activeFilter}
                  updateActiveFilter={updateActiveFilter} />
              </div>
              <div className="col-sm-10 insight-details-container">
                <div className="backlink"
                    onClick={() => history.goBack()}>
                  <div className="back-btn">
                    <BackArrowSVG />
                    <span style={{marginLeft: "4px"}}>Back</span>
                  </div>
                </div>
                <div className="row">
                  <div className="insight-headline">
                    {getInsightLabel(insight.insightType)}
                    <span className="insight-title">
                      {insight.id}
                    </span>
                  </div>
                </div>
                <SummaryCard
                  description={insight.description}
                  name={insight.displayName}
                  type={insight.insightType}
                  lastUpdate={insight.firstSeenAt}
                  risk={insight.risk}
                />
                {(insight.insightType === "Vulnerability") ?
                  <div className="vector-container">
                    <VulnerabilityVectorCard
                      title="Attack Vector"
                      type="vector"
                      listItems={[insight.attackVector]}
                    />
                    <div className="seperator">
                      <SeperatorSVG />
                    </div>
                    <VulnerabilityVectorCard
                      title="Weakness & Exploit"
                      type="weaknesses"
                      listItems={insight.weaknesses}
                    />
                    <div className="seperator">
                      <SeperatorSVG />
                    </div>
                    <VulnerabilityVectorCard
                      title="Consequence"
                      type="consequences"
                      listItems={insight.consequences}
                    />
                  </div>
                  : null}
                  <DetailsSection
                    data={insightTableData}
                    filterDone={filterDone}
                    setFilterDone={setFilterDone}
                  />
              </div>
            </div>
          }

      </div>
    )
}
