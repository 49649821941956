export const AUTH_TOKEN = 'auth-token'
export const ITEM_PER_PAGE = 5
export const BACKEND_GRAPHQL_URI = process.env.REACT_APP_BACKEND_GRAPHQL_URI ? process.env.REACT_APP_BACKEND_GRAPHQL_URI : 'https://api.staging.cisoscope.com/graphql'
export const COLOR_RISK_CRITICAL = '#AD45B9'
export const COLOR_RISK_HIGH = '#DA4347'
export const COLOR_RISK_MEDIUM = '#FF841E'
export const COLOR_RISK_LOW = '#FCBF24'
export const COLOR_RISK_UNKOWN = '#808C9C'
export const COLOR_FILTER_COUNT = 'rgba(128, 140, 156, 0.1)'

export const HOTJAR_ID = process.env.HOTJAR_ID ? process.env.HOTJAR_ID : '2313788';
export const HOTJAR_SNIPPET_VERSION = process.env.HOTJAR_SNIPPET_VERSION ? process.env.HOTJAR_SNIPPET_VERSION : '6';

// export const COLOR_RISK_CRITICAL = 'rgba(233, 46, 25, 0.6)'
// export const COLOR_RISK_HIGH = 'rgba(241, 127, 1, 0.6)'
// export const COLOR_RISK_MEDIUM = 'rgba(248, 238, 29, 0.6)'
// export const COLOR_RISK_LOW = 'rgba(54, 130, 243, 0.6)'

//https://api.staging.cisoscope.com/graphql new API
//https://api.cisoscope.com/api/v1/graphql  old API
