import React, { useState } from "react";

import './DynamicTable.css';

import { select } from '../../utils/Functionals';

import { NextPageSVG, PrevPageSVG } from "../SVG/common";


/**
* Decide wheter to render a field with the given name `fieldName` or to skip it.
* Takes the `fields` declaration of the table describign the data.
*/
const shouldRenderField = (fieldName, fields) => {
  let keys = new Set( select(fields, (o) => o["name"]) );
  return keys.has(fieldName);
}


/**
* Pagination for a Dynamic Table
*/
const PaginationWidget = ({current, setCurrent, totalPages}) => {

  // predicate function to decide whether or not to show a certain page in the navigation.
  // returns an array with the page indices to show or null for a page index that is not shown.
  let satisfactory = (idx) => {
    switch(idx) {
      case 1:           return 1;
      case totalPages:  return idx;
      case current-1:   return idx;
      case current:     return current;
      case current+1:   return idx;
      default:          return -1;
    }
  }

  // squash adjacend equal values in an array.
  // e.g. [1 2 2 3 3 3 6 3 3 1] -> [1 2 3 6 3 1]
  const squashAdjacents = (col) => {
    return col.reduce((acc, e) => {
            if(acc[acc.length-1] === e) {
              return acc;
            } else {
              acc.push(e);
              return acc;
            }
          }, []);
    }

  // predicate to test if an element is null
  const notNull = (elem) => (null === elem || undefined === elem) ? false : true


  return (
    <nav className="table-pagination">
      <div className="page-index">
        <ul>
          {squashAdjacents(Array(totalPages).fill().map((_, idx) => satisfactory(idx+1)).filter(notNull)).map(
            (page) => {
              return (
                (page === -1)
                  ?
                  <li className="not-clickable">
                  ...
                  </li>
                  :
                  <li key={"page"+page}
                      className={(page === current) ? "active" : ""}
                      onClick={() => setCurrent(page)}>
                    {page}
                  </li>
            )}
          )}
        </ul>
      </div>
      <div className="page-navigation">
        <ul>
          <li onClick={() => setCurrent(Math.max(1, current - 1))}>
            <PrevPageSVG width={12} height={12}/>
          </li>
          <li onClick={() => setCurrent(Math.min(current + 1, totalPages))}>
            <NextPageSVG width={12} height={12}/>
          </li>
        </ul>
      </div>
    </nav>
  );
}


/**
* A grouped row in the dynamic table. Takes the `row` data, a `fields` declaration describing
* what fields should be rendered and how to render them. The `childrenFields` declaration
* is analog. The `widgetFn` is a function that takes the name of a table field, its value and the
* shared state of the group. It must return a component that is rendered as a table cell. The
* `childrenWidgetFn` is analog for the child rows.
*/
const TableGroupRow = ({row, fields, childrenFields, grandChildrenFields = null, widgetFn, childrenWidgetFn, grandChildrenWidgetFn}) => {
  console.log("R:", grandChildrenFields, row)
  let [expanded, setExpanded] = useState(false);

  let sharedState = {expanded: expanded,
                     toggleExpanded: () => {setExpanded(!expanded)}}

  return (
    <div className={expanded ? "dynamic-table tbody-row" : "dynamic-table"}>
      <tr className="no-hover">
        {Object.entries(row).map( ([key, value], idx) => {
          if(shouldRenderField(key, fields)) {
            return <td key={key}>{widgetFn(key, value, row, sharedState)}</td>
          } else {
            return null
          }})}
      </tr>
      {expanded &&
        row.children.map( (child ) => {
          if(child.children !== null && child.children !== undefined && child.children.length !== 0) {
            return (<TableGroupRow
                      key={child.name}
                      row={child}
                      fields={fields}
                      childrenFields={grandChildrenFields}
                      widgetFn={widgetFn}
                      childrenWidgetFn={grandChildrenWidgetFn}
                    />)
          } else {
            return (
              <tr key={Math.floor(Math.random() * 10001)} className="child-row no-hover">
                {Object.entries(child).map( ([key, value], idx) => {
                  if(shouldRenderField(key, childrenFields)) {
                    return <td key={key}>{childrenWidgetFn(key, value, child)}</td>;
                  } else {
                    return null;
                  }
                })}
              </tr>
            )}})}
    </div>)
}

export { TableGroupRow, PaginationWidget };
