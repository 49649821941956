import React from 'react';
import styled from "styled-components"

const Styles = styled.div`
/* Auto Layout */

padding: 4px 6px;

/* Dark Grey */

background: #506780;
border-radius: 4px;
color: #ffffff;

width: fit-content;

font-style: normal;
font-weight: 600;
font-size: 12px;
line-height: 15px;
/* identical to box height */

text-transform: uppercase;

`

const ComplianceLabel = ({status}) => {
  return (
    <Styles>
      {status}
    </Styles>
  );
}

export default ComplianceLabel;
