import i18n from "i18next";
import {initReactI18next} from "react-i18next";


// the translations
// (tip move them in a JSON file and import them)
const resources = {
    en: {
        translation: {
            "devices": "devices",
            "Devices": "Devices",
            "reports": "reports",
            "Reports": "Reports",
            "Tasks": "Tasks",
            "tasks": "tasks",
            "Knowledge Graph": "Knowledge Graph",
            "knowledge graph": "knowledge graph",
            "Settings": "Settings",
            "Help": "Help",
            "Back to Dashboard": "Back to Dashboard",
            "Back to Home": "Back to Home",
            "Page Not Found": "Page Not Found",
            "It looks like you found a glitch in the matrix...": "It looks like you found a glitch in the matrix...",
            "It looks like you found a glitch in the matrix! Agent Smith has already been informed.": "It looks like you found a glitch in the matrix! Agent Smith has already been informed.",
            "Vulnerabilities": "Vulnerabilities",
            "VULNERABILITY": "Vulnerability",
            "COMPLIANCE": "Compliance",
            "BUSINESS_DOMAIN": "Business Domain",
            "INSIGHT_TYPE": "Type",
            "Threats": "Threats",
            "Compliance Violations": "Compliance Violations",
            "Threats by Risk-Level": "Threats by Risk-Level",
            "Vulnerabilities by Severity": "Vulnerabilities by Severity",
            "RISK": "Risk",
            "Risk level": "Risk level",
            "Risk Level": "Risk Level",
            "Operating system": "Operating system",
            "OPERATING_SYSTEM": "Operating System",
            "Severity": "Severity",
            "Last scanned at": "Last scanned at",
            "Search": "Search",
            "low": "low",
            "mid": "mid",
            "high": "high",
            "critical": "critical",
            "Scan Coverage (last 24 hours)": "Scan Coverage (last 24 hours)",
            "today": "24 h",
            "last3days": "3 days",
            "lastweek": "7 days",
            "lastmonth": "30 days",
            "lastyear": "> 30 days",
            "STATE": "State",
            "ACTIVE": "Active",
            "INACTIVE": "Inactive",
            "MISSING": "Missing",
            "RESPONSE": "Response",
            "DONE": "Done",
            "TRUE": "True",
            "FALSE": "FALSE",
            "NEW": "New",
            "ACCEPT": "Accept",
            "ELIMINATE": "Eliminate",
            "MITIGATE": "Mitigate",
            "TOLERATE": "Tolerate",
            "FALSE_POSITIVE": "False Positive"
        }
    },
    de: {
        translation: {
            "devices": "Geräte",
            "Devices": "Geräte",
            "reports": "Berichte",
            "Reports": "Berichte",
            "Tasks": "Aufgaben",
            "tasks": "Aufgaben",
            "Knowledge Graph": "Wissensgraph",
            "knowledge graph": "Wissensgraph",
            "Settings": "Einstellungen",
            "settings": "Einstellungen",
            "Help": "Hilfe",
            "help": "Hilfe",
            "Back to Dashboard": "Zurück zum Dashboard",
            "Back to Home": "Zurück zur Startseite",
            "Page Not Found": "Seite nicht gefunden",
            "It looks like you found a glitch in the matrix! Agent Smith has already been informed.": "Es sieht so aus, als hätten Sie einen Fehler in der Matrix gefunden! Agent Smith ist bereits informiert.",
            "Vulnerabilities": "Schwachstellen",
            "VULNERABILITY": "Schwachstelle",
            "BUSINESS_DOMAIN": "Business Domain",
            "INSIGHT_TYPE": "Type",
            "Threats": "Bedrohungen",
            "Compliance Violations": "Richtlinienverstöße",
            "Threats by Risk-Level": "Bedrohungen (nach Risikolevel)",
            "Vulnerabilities by Severity": "Schwachstellen (nach Schweregrad)",
            "RISK": "Risikolevel",
            "Risk level": "Risikolevel",
            "Risk Level": "Risikolevel",
            "Operating system": "Betriebssystem",
            "OPERATING_SYSTEM": "Betriebssystem",
            "Severity": "Schweregrad",
            "Last scanned at": "Letzter Scan",
            "Search": "Suche",
            "low": "Niedrig",
            "mid": "Moderat",
            "high": "Hoch",
            "critical": "Kritisch",
            "Scan Coverage (last 24 hours)": "Scan-Abdeckung (letzte 24 Stunden)"
        }
    }
};

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: "en",

        keySeparator: false, // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;
