import { useEffect } from "react"

/**
* Custom hook to handle clicks outside of the component.
* Takes a ref to the element/component and a handler function that is invoked
* when a user clicks outside the element. Uses a global event listener.
*/
const useOnClickOutsideHook = (ref, updateState) => {
  useEffect(() => {
    // event listener to handle the click (global event listener!)
    let handler = (event) => {
      if(ref.current && !ref.current.contains(event.target))
        updateState();
    }
    // register handler that checks if a click was made outside the component.
    // if the click was outside the component, execute updateState()
    document.addEventListener("mousedown", handler);
    // return a funtion that unregisteres the event listener
    return () => {
      document.removeEventListener("mousedown", handler);
    }
  }, [ref])
}


export { useOnClickOutsideHook }
