import { Trans } from "react-i18next";
import React from "react";
import './RiskMatrix.css'
import RiskMatrixLabel from "./RiskMatrixLabel";

const RiskMatrix = ({ data }) => {
  // go through the data and render the values in the cells of the risk matrix
  const riskMatrixCells = data;
  return (

    <table className="risk-matrix separate">
      <tbody>
        <tr >
          <th className="td-heading"><Trans>Critical</Trans></th>
          {riskMatrixCells.map((cell, index) => cell.likelihood === 'CRITICAL' && (
            <td key={index} className={"table-cell active " + cell.likelihood.toLowerCase() + " " + cell.impact.toLowerCase()}>
              {cell.count ?
                <RiskMatrixLabel impact={cell.impact.toLowerCase()} likelihood={cell.likelihood.toLowerCase()} value={cell.count} /> : ""}
            </td>))}
        </tr>
        <tr>
          <th className="td-heading"><Trans>High</Trans></th>
          {riskMatrixCells.map((cell, index) => cell.likelihood === 'HIGH' && (
            <td key={index} className={"table-cell active " + cell.likelihood.toLowerCase() + " " + cell.impact.toLowerCase()}>
              {cell.count ?
                <RiskMatrixLabel impact={cell.impact.toLowerCase()} likelihood={cell.likelihood.toLowerCase()} value={cell.count} /> : ""}</td>))}</tr>
        <tr>
          <th className="td-heading"><Trans>Medium</Trans></th>
          {riskMatrixCells.map((cell, index) => cell.likelihood === 'MEDIUM' && (
            <td key={index} className={"table-cell active " + cell.likelihood.toLowerCase() + " " + cell.impact.toLowerCase()}>
              {cell.count ?
                <RiskMatrixLabel impact={cell.impact.toLowerCase()} likelihood={cell.likelihood.toLowerCase()} value={cell.count} /> : ""}            </td>))}</tr>
        <tr>
          <th className="td-heading"><Trans>Low</Trans></th>
          {riskMatrixCells.map((cell, index) => cell.likelihood === 'LOW' && (
            <td key={index} className={"table-cell active " + cell.likelihood.toLowerCase() + " " + cell.impact.toLowerCase()}>
              {cell.count ?
                <RiskMatrixLabel impact={cell.impact.toLowerCase()} likelihood={cell.likelihood.toLowerCase()} value={cell.count} /> : ""}     </td>))}</tr>
        <tr>
          <th></th>
          <th className="th-heading">{"Low"}</th>
          <th className="th-heading">{"Medium"}</th>
          <th className="th-heading">{"High"}</th>
          <th className="th-heading">{"Critical"}</th>
        </tr>
      </tbody>
    </table>

  )
}
export default RiskMatrix