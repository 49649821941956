import React, { useState, useRef } from "react";

import './Fields.css';

import {Trans, useTranslation} from "react-i18next";
import { NavLink } from "react-router-dom";

import gql from 'graphql-tag';
import {useQuery, useMutation} from 'react-apollo';

import { BracketArrowRightSVG, DropdownArrowSVG } from "../SVG/common";
import Loader from "../Common/Loader";
import { ResponseLabel, enumToString, stringToEnum, responseOptions } from "../Common/ResponseLabel";

import { useOnClickOutsideHook } from '../../utils/hooks';


const GET_USERS = gql`
  query Users($tenantId: String!){
    users(tenantId: $tenantId) {
      __typename
      id
      firstName
      lastName
      email
      isActive
    }
  }`


const ADD_SUBSCRIBER_MUTATION = gql`
  mutation addSubscriberToFindingMutation($tenantId: String!, $userId: String!, $findingId: String!) {
    addSubscriberToFinding(tenantId: $tenantId, userId: $userId, findingId: $findingId) {
      __typename
      findings {
        findingId
        businessDomain {
          displayName
        }
        device {
          id
          displayName
        }
        risk
        response

        platform {
          id
          name
          version
        }

        subscribers {
          __typename
          id
          email
          firstName
          lastName
        }

        ticketId
      }
    }
  }
`

const REMOVE_SUBSCRIBER_MUTATION = gql`
  mutation removeSubscriberFromFindingMutation($tenantId: String!, $userId: String!, $findingId: String!) {
    removeSubscriberFromFinding(tenantId: $tenantId, userId: $userId, findingId: $findingId) {
      __typename
      findings {
        id
        findingId
        businessDomain {
          id
          displayName
        }
        device {
          id
          displayName
        }
        risk
        response

        platform {
          id
          name
          version
        }

        subscribers {
          __typename
          id
          email
          firstName
          lastName
        }
        ticketId
      }
    }
  }
`

const SET_FINDING_TICKET_ID_MUTATION = gql`
  mutation setFindingTicketIdMutation($tenantId: String!, $findingId: String!, $ticketId: String!) {
    setFindingTicketId(tenantId: $tenantId, findingId: $findingId, ticketId: $ticketId) {
      __typename
      findings {
        id
        findingId
        businessDomain {
          id
          displayName
        }
        device {
          id
          displayName
        }
        platform {
          id
          name
          version
        }
        done
        response
        subscribers {
          __typename
          id
          email
          firstName
          lastName
        }
        risk
        ticketId
      }
    }
  }`

  const UNSET_FINDING_TICKET_ID_MUTATION = gql`
    mutation unsetFindingTicketIdMutation($tenantId: String!, $findingId: String!) {
      unsetFindingTicketId(tenantId: $tenantId, findingId: $findingId) {
        __typename
        findings {
          id
          findingId
          businessDomain {
            id
            displayName
          }
          device {
            id
            displayName
          }
          platform {
            id
            name
            version
          }
          done
          response
          subscribers {
            __typename
            id
            email
            firstName
            lastName
          }
          risk
          ticketId
        }
      }
    }`

const SET_FINDING_RESPONSE_MUTATION = gql`
mutation setFindingResponseMutation($tenantId: String!, $findingId: String!, $response: FindingResponse!) {
  setFindingResponse(tenantId: $tenantId, findingId: $findingId, response: $response) {
    __typename
    findings {
      id
      findingId
      businessDomain {
        id
        displayName
      }
      device {
        id
        displayName
      }
      platform {
        id
        name
        version
      }
      done
      response
      subscribers {
        __typename
        id
        email
        firstName
        lastName
      }
      risk
      ticketId
    }
  }
}
`

const SET_FINDING_DONE_MUTATION = gql`
mutation setFindingDoneMutation($tenantId: String!, $findingId: String!) {
  setFindingAsDone(tenantId: $tenantId, findingId: $findingId) {
    __typename
    findings {
      id
      findingId
      businessDomain {
        id
        displayName
      }
      device {
        id
        displayName
      }
      platform {
        id
        name
        version
      }
      done
      response
      subscribers {
        __typename
        id
        email
        firstName
        lastName
      }
      risk
      ticketId
    }
  }
}
`

const SET_FINDING_NOT_DONE_MUTATION = gql`
mutation setFindingNotDoneMutation($tenantId: String!, $findingId: String!) {
  setFindingAsNotDone(tenantId: $tenantId, findingId: $findingId) {
    __typename
    findings {
      id
      findingId
      businessDomain {
        id
        displayName
      }
      device {
        id
        displayName
      }
      platform {
        id
        name
        version
      }
      done
      response
      subscribers {
        __typename
        id
        email
        firstName
        lastName
      }
      risk
      ticketId
    }
  }
}
`


const ResponseWidget = ({setResponse, isDone, toggleDone}) => {


  return (
    <div className="response-widget">
      <ul>
        {responseOptions.map((option) => { return (
          <li key={option}
              onClick={(e) => {setResponse(option); e.stopPropagation()}}>
            <ResponseLabel labels={option}/>
          </li>
        )})}
      </ul>
      <div className="response-done-container"
           onClick={(e) => {toggleDone(); e.stopPropagation();} }>
        <input type="checkbox"
               id="yes"
               checked={isDone}/>
        <label htmlFor="yes"><Trans>Done</Trans></label>
      </div>
    </div>
  )
}

const ResponseField = ({response, done, postUpdate, postDone, tenantId, findingId, disabled}) => {
  let [showWidget, setShowWidget] = useState(false);
  const toggleWidget = () => setShowWidget(!showWidget);


  // set response with a gql mutation
  const [setResponseMutation] = useMutation(SET_FINDING_RESPONSE_MUTATION, {
          onError: ({graphQLErrors, networkError}) => {
              console.error("Mutation setFindingResponseMutation Failed!")
          },
          refetchQueries: ["InsighDetail"],
          onCompleted: (data) => {
            console.log("Mutation setFindingResponseMutation Successful!")
          }
      }
  );

  // set done with a gql mutation
  const [setDoneMutation] = useMutation(SET_FINDING_DONE_MUTATION, {
          onError: ({graphQLErrors, networkError}) => {
              console.error("Mutation setFindingDoneMutation Failed!")
          },
          refetchQueries: ["InsightDetail"],
          onCompleted: (data) => {
            console.log("Mutation setFindingDoneMutation Successful!")
          }
      }
  );

  // set NOT done with a gql mutation
  const [setNotDoneMutation] = useMutation(SET_FINDING_NOT_DONE_MUTATION, {
          onError: ({graphQLErrors, networkError}) => {
              console.error("Mutation setFindingNotDoneMutation Failed!")
          },
          refetchQueries: ["InsightDetail"],
          onCompleted: (data) => {
            console.log("Mutation setFindingNotDoneMutation Successful!")
          }
      }
  );

  const setResponse = (response) => {
    setResponseMutation({variables: {tenantId, findingId, response}});
    setShowWidget(false)
    postUpdate(response);
  }

  const toggleDone = () => {
    done ? setNotDoneMutation({variables: {tenantId, findingId}}) : setDoneMutation({variables: {tenantId, findingId}});
    setShowWidget(false)
    postDone();
  }



  // close the AddTicketWidget when a user clicks outside of it
  let responseRef = useRef(null);
  useOnClickOutsideHook(responseRef, () => setShowWidget(false) );

  // console.log("ResponseField: ", response)

  return (
    <div className={disabled ? "response-field no-hover" : "response-field"}
         ref={responseRef}
         onClick={(e) => {if(!disabled) {toggleWidget(); e.stopPropagation();}}}>
      {}
      {disabled &&
        <div className="response-label-container">
          {done ? <ResponseLabel labels="DONE"/> : <ResponseLabel labels={response}/>}
        </div>}
      {/* There is a response and it can be changed */}
      {((response !== null) && (response !== undefined) && !disabled) &&
        <div className="response-label-container">
          {done ? <ResponseLabel labels={"DONE"}/> : <ResponseLabel labels={response}/>}
            <span className="">
              <DropdownArrowSVG color={"#0F57A6"}/>
            </span>
        </div>
      }
      {/* This one is done and can't be changed anymore */}
      {/*(!disabled && done) &&
        <div className="response-label-container">
          <ResponseLabel labels={"DONE"}/>
        </div>
      */}
      {showWidget &&
      <ResponseWidget
        setResponse={setResponse}
        isDone={done}
        toggleDone={toggleDone}
      />}
    </div>
  )
}

/**
* Aggregate Component to add a ticket by an input string set interactively by a user.
*/
const AddTicketWidget = ({tenantId, setTicket}) => {
  const { t } = useTranslation();
  let [text, setText] = useState("");

  return (
    <div className="ticket-widget">
      <input type="text"
             autoFocus={true}
             value={text}
             placeholder={t("Add Ticket")}
             onChange={(e) => setText(e.target.value)}
             onKeyPress={(e) => { if( (e.key || e.code) === "Enter" ) setTicket(text)}}
      />
    </div>)
}

/**
* A field for the dynamic table to render a ticketID with modification functionality.
*/
const TicketField = ({ticketId, postAdd, postRemove, tenantId, findingId, disabled}) => {
  let [showAddWidget, setShowAddWidget] = useState(false);

  // set ticket with a gql mutation
  const [setTicketMutation] = useMutation(SET_FINDING_TICKET_ID_MUTATION, {
          onError: ({graphQLErrors, networkError}) => {
              console.error("Mutation setTicketMutation Failed!")
          },
          refetchQueries: ["InsightDetail"],
          onCompleted: (data) => {
            console.log("Mutation setTicketMutation Successful!")
          }
      }
  );

  // unset ticket with a gql mutation
  const [unsetTicketMutation] = useMutation(UNSET_FINDING_TICKET_ID_MUTATION, {
          onError: ({graphQLErrors, networkError}) => {
              console.error("Mutation unsetTicketMutation Failed!")
          },
          refetchQueries: ["InsightDetail"],
          onCompleted: (data) => {
            console.log("Mutation unsetTicketMutation Successful!")
          }
      }
  );

  // callback invoked by the AddTicketWidget when a ticket should be added. the `ticketId`
  //argument must be a string.
  // Invokes the postAdd hook that can be set by the parent component afterwards.
  const onSetTicket = (ticketId) => {
    setShowAddWidget(false)

    setTicketMutation({variables: {tenantId, findingId, ticketId}});
    postAdd(ticketId)
  }

  // callback invoked by the unsetTicket when a ticket should be removed.
  // Invokes the postAdd hook that can be set by the parent component afterwards.
  const onUnsetTicket = () => {
    setShowAddWidget(false)
    unsetTicketMutation({variables: {tenantId, findingId}});
    postAdd(findingId)
  }

  // close the AddTicketWidget when a user clicks outside of it
  let setTicketRef = useRef(null);
  useOnClickOutsideHook(setTicketRef, () => setShowAddWidget(false) );

  return (
    <div className={disabled ? "ticket-field no-hover" : "ticket-field"}
         ref={setTicketRef}>
      {/* field disabled/field is part of expandable row (summary)*/}
      {disabled && <span>{ticketId+" Tickets"}</span>}
      {/* field is enabled and there is a valid ticket present */}
      {(!disabled && ticketId !== null && ticketId !== undefined) &&
        <div className="ticket">
          <span className="ticket-id">{ticketId}</span>
          <span className="rm-ticket"
                onClick={(e) => onUnsetTicket()}>
            &times;
          </span>
        </div>
      }
      {/* field is enabled and there is NO valid ticket present */}
      {(!disabled && (ticketId === null || ticketId === undefined)) &&
        <AddTicketWidget
          tenantId={tenantId}
          setTicket={onSetTicket}
        />
      }
    </div>
  )
}



/**
* Composit used in the SubscriberField component to render the name of a subscriber including a
* remove option. Takes the `name` of the subscriber as string an a `remove` function that is invoked
* when a user clicks the remove user option.
*
*/
const Subscriber = ({name, remove}) => {
  return (
    <div className="subscriber">
      <span className="subscriber-name">{name}</span>
      { remove != null &&
        <span className="subscriber-rm-btn"
              onClick={remove}>
          &times;
        </span>}
    </div>
  )
}

/**
* Composit used in the SubscriberField component to render the "+ X more" button to expand the
* subscriber field to show all subscribers.
* Takes the `count` of the remaining subscribers (that is total - 1) and a function `onToggle` that
* gets executet when a user clicks on the element.
*/
const SubscriberMore = ({count, onToggle}) => {
  return (
    <span className="subscriber-more"
          onClick={(e) => { onToggle(e); e.stopPropagation(); }}>
      {"+"+count}
    </span>
  )
}


/**
* Autocomplete List for the users that can be added as subscribers.
* Fetches all available users and presents them filtered using the current input.
* Takes the tenandId, the search text entered by the user and a callback used to
* add the user. The callback should perform a gql mutation.
*
* The search term is matched against the following user properties:
* - First Name
* - Last Name
* - E-Mail Address
*/
const SubscriberAutoCompleteWidget = ({tenantId, input, addSubscriber}) => {
  // Get the data from the backend
  const { loading, error, data } = useQuery(GET_USERS, {
    variables: { tenantId: tenantId }
  });

  if(loading) {
    return <Loader />;
  }

  if(error) {
    console.error("Failed to get the users list from server!")
    return null;
  }

  let matchExpression = new RegExp(input, 'gi')

  const filterByInput = (user) => (user.firstName.match(matchExpression) ||
                                   user.lastName.match(matchExpression) ||
                                   user.email.match(matchExpression) )

  return (
    (input.length < 2)
    ? null
    :
    <div className="subscriber-autocomplete-widget">
      <ul>
        {data.users.filter(filterByInput).map((user) => {
          return (
            <li key={user.email}
                onClick={(e) => {addSubscriber(user); e.stopPropagation();} }>
              {user.firstName + " " + user.lastName}
            </li>)
        })}
      </ul>
    </div>
  )
}

/**
* Aggregate Component to add a subscriber by an input string set interactively by a user.
*/
const AddSubscriberWidget = ({tenantId, addSubscriber}) => {
  const { t } = useTranslation();
  let [text, setText] = useState("");

  return (
    <div className="subscriber-widget">
      <input type="text"
             autoFocus={true}
             value={text}
             placeholder={t("Add Subscriber")}
             onChange={(e) => setText(e.target.value)}

      />
      <SubscriberAutoCompleteWidget
        tenantId={tenantId}
        input={text}
        addSubscriber={addSubscriber}
      />
    </div>)
}

/**
* A field for the dynamic table to render a list of subscribers.
*/
const SubscriberField = ({subscribers, postAdd, postRemove, tenantId, findingId, disabled}) => {
  const [showAll, setShowAll] = useState(false);
  const toggleShowAll = (e) => { setShowAll(!showAll);
                                 setShowAddSubscriberWidget(false);
                               };

  let [showAddSubscriberWidget, setShowAddSubscriberWidget] = useState(false);

  // add a subscriber with a gql mutation
  const [addMutation] = useMutation(ADD_SUBSCRIBER_MUTATION, {
          onError: ({graphQLErrors, networkError}) => {
              console.error("Mutation AddSubscriber Failed!")
          },
          refetchQueries: ["InsightDetail"],
          onCompleted: (data) => {
            //console.log("Mutation AddSubscriber Successful!")
          }
      }
  );

  // remove a subscriber with a gql mutation
  const [rmMutation] = useMutation(REMOVE_SUBSCRIBER_MUTATION, {
          onError: ({graphQLErrors, networkError}) => {
              console.error("Mutation RemoveSubscriber Failed!")
          },
          refetchQueries: ["InsightDetail"],
          onCompleted: (data) => {
            //console.log("Mutation RemoveSubscriber Successful!")
          }
      }
  );


  // callback invoked by the AddSubscriberWidget when a user should be added. the `user` argument
  // must be a valid user object with an `id`.
  // Invokes the postAdd hook that can be set by the parent component afterwards.
  const onAdd = (user) => {
    console.log("ADD user: ", user)
    setShowAddSubscriberWidget(false)
    let userId = user.id;
    addMutation({variables: {tenantId, userId, findingId}});
    postAdd(user)
  }

  // callback invoked  when a user should be removed. the `user` argument must be a valid user
  // object with an `id`.
  // Invokes the postRemove hook that can be set by the parent component afterwards.
  const onRemove = (user) => {
    console.log("RM user: ", user)
    let userId = user.id;
    rmMutation({variables: {tenantId, userId, findingId}});
    postRemove()
  }

  // close the AddSubscriberWidget when a user clicks outside of it
  let addSubscriberRef = useRef(null);
  useOnClickOutsideHook(addSubscriberRef, () => setShowAddSubscriberWidget(false) );

  return (
    <div className={disabled ? "subscriber-field no-hover" : "subscriber-field"}
         onClick={() => {if(!disabled) setShowAddSubscriberWidget(true)}}
         ref={addSubscriberRef}>
      {( subscribers === null || subscribers === undefined || subscribers.length === 0) ?
        (!showAddSubscriberWidget && !disabled) &&
          <span className="add-subscriber"
                onClick={() => setShowAddSubscriberWidget(true)}>
            <Trans>Add subscriber</Trans>
          </span>
        :
        !disabled ?
          showAll ?
            subscribers.map((subscriber) => {return (
              <div className="subscriber-container"
                   key={findingId + subscriber.id}>
                <Subscriber
                  name={subscriber.firstName + " " + subscriber.lastName}
                  remove={() => onRemove(subscriber)}
                />
              </div>)})
            :
            <div className="subscriber-container">
              <Subscriber
                name={subscribers[0].firstName + " " + subscribers[0].lastName}
                remove={() => onRemove(subscribers[0])}
              />
              {subscribers.length > 1 &&
                <SubscriberMore
                  count={subscribers.length-1}
                  onToggle={toggleShowAll}
                />}
            </div>
          :
          <div className="subscriber-container">
            <Subscriber
              name={subscribers[0].firstName + " " + subscribers[0].lastName}
              remove={null}
            />
            {subscribers.length > 1 &&
              <SubscriberMore
                count={subscribers.length-1}
                onToggle={!disabled ? toggleShowAll : ()=>{}}
              />}
          </div>
        }
        {showAddSubscriberWidget && <AddSubscriberWidget addSubscriber={onAdd} tenantId={tenantId}/>}
        {showAll &&
          <div className="show-less"
               onClick={(e) => {setShowAll(false); e.stopPropagation();}}>
            <span><Trans>Show less</Trans></span>
            <div className="rotate-180">
              <DropdownArrowSVG
                color={"#B5C1CE"}
              />
            </div>
          </div>}
    </div>
  )
}

/**
* A simple text field for a dynamic table. Simply render the given text.
*/
const TableTextField = ({value, props}) => {
  return (
    <span onClick={props.onClick}>
      {value}
    </span>
  )
}

/**
* A text field with limited character width.
* Shows a tooltip on hovering when the text was cropped.
*/
const TableLimitedTextField = ({value, limit = 25, props}) => {
  let cropped = (value.length >= limit) ? value.substring(0, limit)+"..." : value;
  return (
    <span className="limited-text-field"
          onClick={props.onClick}>
      {cropped}
      {(value.length >= limit) && <span className="hovering-full-text">{value}</span>}
    </span>
  )
}

/**
* A simple text field for a dynamic table with link behaviour. Create a navigation link with the
* given display text. Will navigate the user to the given location on click.
*/
const TableTextFieldWithLink = ({value, location, props}) => {
  return (
    <NavLink to={location}>
      <span>{value}</span>
    </NavLink>
  )
}

/**
* A text field for a dynamic table with an expansion option. Used for table rows that are parents
* of detailed child entries.
* Takes the text of the field `value`, a boolean state `expanded` to indicate whether the children
* should be shown or not and a function `toggleExpanded` that is invoked when a user clicks the
* expand/collapse arrow.
*/
const TableTextFieldWithExpand = ({value, expanded, toggleExpanded, enabled = true}) => {
  return (
    <div className="text-field-with-expansion" onClick={enabled ? toggleExpanded : () => {}}>
      <div className={expanded ? "group-expanded" : "group-not-expanded"}><BracketArrowRightSVG width={16} height={12} color={"#0D276B"}/></div>
      {value}
    </div>
  )
}

const InsightTypeField = ({type, count, limit = 25, props = {}}) => {
  let typeName = "";
  switch(type) {
    case "vulnerability": typeName = "Vulnerabilities"; break;
  };
  let cropped = (typeName.length >= limit) ? typeName.substring(0, limit)+"..." : typeName;
  return (
    <span className="limited-text-field"
          onClick={props.onClick}>
      <Trans>{cropped}</Trans>
      {" ("+count+")"}
      {(typeName.length >= limit) && <span className="hovering-full-text">{typeName}</span>}
    </span>)
}


export { TableTextField, TableLimitedTextField, TableTextFieldWithLink, TableTextFieldWithExpand,
         SubscriberField, TicketField, ResponseField, InsightTypeField };
